import { Link } from '@material-ui/core';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  cursor: pointer;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  &:before {
    vertical-align: middle;
    content: '';
    display: inline-block;
    height: 16px;
    width: 16px;
    background-image: ${({ icon }) => `url(${icon})`};
    /* background-size: contain; */
    background-repeat: no-repeat;
    margin-right: 0.5em;
    margin: ${({ $dir }) =>
      `0 ${$dir === 'ltr' ? '4px' : 0} 0 ${$dir === 'rtl' ? '4px' : 0}`};
  }
`;
