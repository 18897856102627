import { Chip } from '@material-ui/core';
import styled from 'styled-components';

export const StyledChip = styled(Chip)`
  &.MuiChip-root {
    border-radius: 13px;
    background-color: #659bf6;
    color: white;
    font-weight: 600;
  }
`;
