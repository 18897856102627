import { Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledPaper } from '../styles/SearchAnimation.styled';
import CompanyCard from './CompanyCard/CompanyCard';
import { getSearchAnimationSuppliers } from '../../../../images/suppliers/suppliers';

const SearchAnimation = ({ country, isMobile }) => {
  let suppliers = getSearchAnimationSuppliers(country);
  const mobslice = isMobile ? 6 : suppliers.length + 1;
  suppliers = suppliers.slice(0, mobslice);
  
  const { t } = useTranslation();
  return (
    <Container maxWidth='md'>
      <StyledPaper $ismobile={isMobile}>
        <Grid container justifyContent='center'>
          <Grid item>
            <Typography variant='h5'>{t('common.searchPageLoading')}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent='center' spacing={2} style={{ marginTop: 32 }}>
          {suppliers.map((company, idx) => {
            return (
              <Grid item key={idx}>
                <CompanyCard company={company} />
              </Grid>
            );
          })}
        </Grid>
      </StyledPaper>
    </Container>
  );
};

export default SearchAnimation;
