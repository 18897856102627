import { Paper, Popper, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { GreenBorderTextField } from '../../../../../../ReusableComponents/StyledComponents';

export const StyledPopper = styled(Popper)`
  & .MuiAutocomplete-groupLabel {
    color: #000;
    font-weight: 600;
    background-color: #e9e9e9;
  }
  width: ${({ $isDifferentLocation, $isMobile }) =>
    $isDifferentLocation && !$isMobile ? '400px  !important' : 'auto'};
`;
export const StyledPaper = styled(Paper)``;

export const SearchLocationInputTextField = styled(GreenBorderTextField)`
  .MuiInputBase-root {
    border-radius: ${({ $isdifferentlocation, ispickupdate }) =>
      $isdifferentlocation ? (ispickupdate ? '2px 0px 0px 2px' : '0px 2px 2px 0px') : '2px'};
  }
  &.MuiFormControl-marginNormal {
    margin-top: 0px;
  }
  .MuiOutlinedInput-input {
    font-weight: 500;
  }
`;
