import React, { useEffect, useState } from 'react';
import ReactHtmlParser, { domToReact } from 'html-react-parser';
import { apiUrl } from '../../../../../utils';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';
import PageNotFound from '../../../../Routes/404/PageNotFound';

const ConditionsSkeleton = () => {
  const arr = new Array(20).fill();
  return (
    <div>
      {arr.map((item, idx) => (
        <Skeleton key={idx} variant="text" />
      ))}
    </div>
  );
};

const SupplierRentalConditions = ({
  country,
  supplier,
  apiType,
  countryID,
  apiData,
  sippCode,
  setAction,
}) => {
  const [conditions, setConditions] = useState();

  const {
    i18n: { languages },
  } = useTranslation();
  const options = {
    replace: (node) => {
      if (node.name === 'body' || node.name === 'head') {
        node.attribs.style = 'white-space: normal; background:red';
        return <>{domToReact(node.children, options)}</>;
      }
    },
  };
  useEffect(() => {
    axios
      .get(
        apiUrl(
          `getrentalconditions?country=${country}&supplier=${supplier}&lng=${
            languages[0]
          }${apiType ? `&apiType=${apiType}` : ''}${
            countryID ? `&destinationID=${countryID}` : ''
          }${apiData && apiData.PULocid ? `&PULocid=${apiData.PULocid}` : ''}${
            apiData && apiData.quoteId ? `&quoteId=${apiData.quoteId}` : ''
          }${sippCode ? `&sippCode=${sippCode}` : ''}`
        )
      )
      .then((res) => setConditions(res.data))
      .catch((err) => {
        setConditions(
          err.response.data.expose ? err.response.data.message : ''
        );
        setAction(err.response.data.action);
        console.log(err);
      });
  }, []);

  return (
    <div style={{ whiteSpace: 'normal' }}>
      {conditions ? (
        ReactHtmlParser(conditions, options)
      ) : conditions === '' ? (
        <PageNotFound />
      ) : (
        <ConditionsSkeleton />
      )}
    </div>
  );
};

export default SupplierRentalConditions;
