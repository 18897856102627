import { Tooltip } from '@material-ui/core';
import React from 'react';

const CarFeatureToolTip = ({ number, isMobile, title, children }) => {
  if (title) {
    return (
      <>
        <Tooltip disableHoverListener={isMobile} title={title} arrow>
          {children}
        </Tooltip>
        <span style={{ verticalAlign: 'middle' }}> {number}</span>
      </>
    );
  } else return children;
};

export default CarFeatureToolTip;
