import {  Grid } from '@material-ui/core';
import { StyledChip } from './CardFilter/styles/CardFilter.styled';

const CarGroupAppliedFilter = ({ carsClasses, setCarsClasses, appliedFilters }) => {
  const handleDelete = name => {
    const tempCarsClasses = [...carsClasses];
    const idx = tempCarsClasses.findIndex(cl => cl.carClass === name);
    tempCarsClasses[idx].isChecked = false;
    setCarsClasses(tempCarsClasses);
  };
  return (
    <>
      {appliedFilters.length > 0 &&
        appliedFilters.map((group, idx) => (
          <Grid item>
            <StyledChip
              key={group.carClass}
              name={group.carClass}
              label={group.carClass}
              variant='outlined'
              onDelete={() => handleDelete(group.carClass)}
            />
          </Grid>
        ))}
    </>
  );
};

export default CarGroupAppliedFilter;
