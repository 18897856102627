import React, { useState, useEffect } from 'react';
import './styles/styles.scss';

import ChatIcon from '@material-ui/icons/Chat';
import {
  MainContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
} from '@chatscope/chat-ui-kit-react';
import message_received_sound from './sounds/message_received.mp3';
import { useTheme } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import useScroll from '../../../../../../utils/hooks/useScroll';
import {
  ChatWrapper,
  StyledChatContainer,
  StyledFloatingButton,
} from './styles/styles';

import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import io from 'socket.io-client';
import HeaderComponent from './components/HeaderComponent';
const env = process.env.NODE_ENV || 'development';
const hostname =
  env === 'production' ? 'https://www.we4rent.com' : 'http://localhost:4000';

function ChatMessage({ body, direction, type }) {
  this.body = body;
  this.direction = direction;
  this.type = type;
}
let socket;
const ChatBox2 = () => {
  const {
    t,
    i18n: {
      languages: [lng],
    },
  } = useTranslation();
  const [chatOpen, setChatOpen] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  // const initial = [
  //   {
  //     body: 'HELLO',
  //     direction: 'outgoing',
  //     position: 'normal',
  //   },
  //   {
  //     body: 'HELLO',
  //     direction: 'outgoing',
  //     position: 'normal',
  //   },
  //   {
  //     body: 'How are you?',
  //     direction: 'incoming',
  //   },
  //   {
  //     body: 'How are you?',
  //     direction: 'incoming',
  //   },
  // ];
  const [messages, setMessages] = useState([]);
  const { crossed } = useScroll(200);

  const pop_message_sound = new Audio(message_received_sound);

  const handleClose = () => {
    setChatOpen(false);
  };
  const buttonRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    console.log('Messages', messages);
  }, [messages]);

  const handleNewMessage = (message, direction, type) => {
    if (direction === 'incoming' && !isMuted) {
      pop_message_sound.play();
    }
    const tmpMessages = messages;
    tmpMessages.push(
      new ChatMessage({ body: message, direction, type: type || 'text' })
    );
    if (direction === 'outgoing') {
      socket.emit('send-message', message, lng);
    }
    setMessages([...tmpMessages]);
  };

  if (chatOpen) {
    if (!socket) {
      socket = io.connect(hostname);
    }

    if (messages.length === 0) {
      socket.emit('greeting-message', lng);
      console.log(`connected to socket with id ${socket.id}`);
    }
  }
  if (socket) {
    socket.on('greeting-message', (message) => {
      handleNewMessage(message, 'incoming');
    });

    socket.on('error-message', (messageFromServer) => {
      //TODO
      handleNewMessage(messageFromServer, 'incoming');
      console.log('ERROR MESSAGE', messageFromServer);
    });
    socket.on('receive-message', (messageFromServer) => {
      console.log('RECEIVED', messageFromServer);
      handleNewMessage(messageFromServer, 'incoming');
    });
  }

  // handleNewUserMessage(messageFromServer);

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (chatOpen) {
          return setChatOpen(false);
        }
      }}>
      <div>
        <StyledFloatingButton
          ref={buttonRef}
          onClick={() => setChatOpen((prevState) => !prevState)}
          color="secondary"
          crossed={crossed ? 1 : 0}
          style={{ position: 'fixed' }}
          ismobile={isMobile}>
          <ChatIcon style={{ color: 'white' }} />
        </StyledFloatingButton>

        <Fade in={chatOpen} timeout={1000}>
          <ChatWrapper
            elevation={5}
            ismobile={isMobile}
            crossed={crossed ? 1 : 0}>
            <MainContainer style={{ borderRadius: '0.7em' }}>
              <StyledChatContainer>
                <ConversationHeader>
                  <ConversationHeader.Content
                    info="Avai"
                    userName="Available"
                    children={
                      <HeaderComponent
                        isMuted={isMuted}
                        setIsMuted={setIsMuted}
                        t={t}
                        handleClose={handleClose}
                        connected={socket?.connected}
                      />
                    }
                  />
                </ConversationHeader>

                <MessageList style={{ paddingTop: 12.8 }}>
                  {messages.map((message, idx) => {
                    return (
                      <Message
                        key={idx}
                        model={{
                          message: message.body,
                          position: message.position,
                          direction: message.direction,
                          type: message.type,
                        }}></Message>
                    );
                  })}
                </MessageList>
                <MessageInput
                  onSend={(m) => handleNewMessage(m, 'outgoing', 'text')}
                  placeholder={t('chat.typeYourMessage')}
                  attachButton={false}
                  disabled={!socket?.connected}
                />
              </StyledChatContainer>
            </MainContainer>
          </ChatWrapper>
        </Fade>
      </div>
    </ClickAwayListener>
  );
};

export default ChatBox2;
