import { Grid } from '@material-ui/core';
import React, { lazy, Suspense } from 'react';
import Title from './Components/Title/Title';
import CarImage from './Components/CarImage';
import CarFeatures from '../../../CardComponents/CarFeatures/CarFeatures';
import { Skeleton } from '@material-ui/lab';
import SupplierImage from './Components/SupplierImage';
const ModelChoiceStamp = lazy(() => import('./Components/ModelChoiceStamp/ModelChoiceStamp'));
// const CarFeatures = () => {
//   return <div style={{ border: '1px solid' }}>Car Features</div>;
// };
const LeftSection = ({
  image,
  carModel,
  carFeaturesProps,
  isLoading,
  isModelGuaranteed,
  supplier,
  ...rest
}) => {
  return (
    <Grid container direction='column' spacing={2} justifyContent='space-between' style={{ height: '100%' }}>
      <Grid item>
        {isLoading ? (
          <Skeleton animation='wave' height={10} width='100%' style={{ marginBottom: 6 }} />
        ) : (
          <Title carModel={carModel} isModelGuaranteed={isModelGuaranteed} {...rest} />
        )}
      </Grid>
      <Grid item style={{ position: 'relative' }}>
        {!isLoading && isModelGuaranteed && (
          <Suspense fallback={<div></div>}>
            <ModelChoiceStamp />
          </Suspense>
        )}
        <CarImage
          image={image}
          carModel={carModel}
          isLoading={isLoading}
          isModelGuaranteed={isModelGuaranteed}
        />
      </Grid>
      <Grid item>
        <CarFeatures {...carFeaturesProps} isLoading={isLoading} sippCode={rest.sipp_code} />
      </Grid>
      {/* <Grid item>
        <Divider style={{ marginTop: 16, marginBottom: 8 }} />
      </Grid> */}
    </Grid>
  );
};

export default LeftSection;
