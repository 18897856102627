import { Collapse } from '@material-ui/core';
import React, { useContext, lazy, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SEOdata } from '../../../utils';
import MainSection from './MainSection/MainSection';
import logo from '../../../images/logo.png';
import {
  SearchDetailsContext,
  BackGroundImageContext,
  LocationsContext,
} from '../../SharedState/SearchState';
import QuickRentButton from './SecondSection/Components/QuickRentButton';
import {
  getBackgroundImage,
  searchLocationsByTerm,
} from '../../../apiRequests';
import localBusinessSchema from '../../../utils/SEO/schemas/localBusinessSchema';
import AboutLocationSection from './MainSection/Components/AboutLocationSection/AboutLocationSection';
import { keywords } from './utils';
// import ChatBox from './SecondSection/Components/ChatBox/ChatBox';
const OrderSection = lazy(() => import('./OrderSection/OrderSection'));
const SecondSection = lazy(() => import('./SecondSection/SecondSection'));

const Main = ({ reviews }) => {
  const {
    t,
    i18n: {
      languages: [lng],
    },
  } = useTranslation();
  const { country, location } = useParams();
  const navigate = useNavigate();
  const [searchDetails, setSearchDetails] = useContext(SearchDetailsContext);
  // const [destination, setDestination] = useState();
  const [backgroundImage, setBackgroundImage] = useContext(
    BackGroundImageContext
  );
  const [orderLoading, setOrderLoading] = useState(false);
  const [locations, setLocations] = useContext(LocationsContext);

  if (country === 'undefined') {
    navigate('/');
  }
  useEffect(() => {
    if (!location && !country) {
      setBackgroundImage('https://i.imgur.com/eO2dPAo.png');
    } else {
      searchLocationsByTerm(null, lng, country, location)
        .then(({ data }) => {
          // console.log("DATA", data)
          if (data.length === 0) {
            navigate('/404');
          } else {
            setLocations({ country, stations: data });
            setSearchDetails({
              ...searchDetails,
              pickUpLocation: data[0],
              dropOffLocation: data[0],
            });
            if (data[0].LocationImage && location) {
              setBackgroundImage(data[0].LocationImage);
            } else {
              getBackgroundImage(location, country)
                .then(({ data: { url } }) => {
                  setBackgroundImage(url);
                })
                .catch((err) => {
                  console.log(err);
                  setBackgroundImage('https://i.imgur.com/eO2dPAo.png');
                });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, location]);

  let place = '';
  let title = '';
  let description = '';

  const { pickUpLocation } = searchDetails;
  const pickUpLocationType = pickUpLocation ? pickUpLocation.LocationType : '';
  // const pickUpLocationLocationName = pickUpLocation.LocationName ? pickUpLocation.LocationName[lng] : '';
  const pickUpLocationName = pickUpLocation.name
    ? pickUpLocation.name[lng]
    : '';
  const pickUpLocationCity = pickUpLocation.city
    ? pickUpLocation.city[lng]
    : '';
  if (!location && !country) {
    title = t('metatags.mainPage.titleWithoutLocation');
    description = t('metatags.mainPage.descriptionWithoutLocation');
  } else {
    const type =
      pickUpLocationType === 'railway'
        ? 'city'
        : searchDetails.pickUpLocation.LocationType;
    const placeType = t(`picker.carpicker.locationSelection.${type}.${type}`, {
      count: 1,
    }).toLocaleLowerCase();
    if (location && pickUpLocation && pickUpLocationType !== 'delivery') {
      place = '';
      //TODO type DELIVERY

      place =
        pickUpLocationType === 'airport'
          ? pickUpLocationName
          : pickUpLocationCity;
      title = t('metatags.mainPage.titleWithLocation', {
        placeType,
        place,
        country: t(`countries.${country}.${country}`, { count: 0 }),
      });
    } else {
      place = t(`countries.${country}.${country}`, { count: 1 });
      title = t('metatags.mainPage.titleWithParams', {
        place,
      });
    }
    description = t('metatags.mainPage.description', {
      place,
      placeType: location ? placeType : '',
    });
  }
  const orderSectionRef = React.useRef(null);
  // console.log('TITLE', title);

  useEffect(() => {
    if (searchDetails.country.isLanding && orderLoading) {
      setTimeout(() => {
        orderSectionRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }, 1000);
    }
  }, [orderLoading]);
  return (
    <React.Fragment>
      <script className="structured_data_schema" type="application/ld+json">
        {localBusinessSchema(lng)}
      </script>
      <SEOdata
        lang={lng}
        title={title}
        description={description}
        image={
          country || location
            ? backgroundImage
            : `${process.env.PUBLIC_URL}${logo}`
        }
        canonicalUrl={`https://www.we4rent.com/${lng}${
          country ? `/country/${country}` : ''
        }${country && location ? `/${location}` : ''}`}
        type="website"
        keywords={keywords[lng]}
      />
      <MainSection
        locationName={pickUpLocation.LocationName[lng]}
        country={country}
        location={location}
        title={title}
        orderLoading={orderLoading}
        setOrderLoading={setOrderLoading}
      />

      {country && (
        <AboutLocationSection country={country} location={location} />
      )}
      <Suspense fallback={<div></div>}>
        <Collapse
          in={searchDetails.country.isLanding && orderLoading}
          timeout={4000}>
          {/* <Collapse in={true}> */}{' '}
          {searchDetails.country.isLanding && (
            <OrderSection
              selectedCar={searchDetails.landing.selectedCar}
              ref={orderSectionRef}
              lng={lng}
              orderLoading={orderLoading}
              setOrderLoading={setOrderLoading}
            />
          )}
        </Collapse>
      </Suspense>

      <div style={{ backgroundColor: 'rgb(250, 250, 250)', marginTop: 16 }}>
        <Suspense fallback={<div>{t('common.loading')}</div>}>
          <SecondSection reviews={reviews} />
        </Suspense>
      </div>
    </React.Fragment>
  );
};

export default Main;
