import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';

export const ButtonWithLoader = ({
  isLoading,
  onClickHandler,
  name,
  buttonVariant,
  buttonColor,
  disabled,
  color,
  loadingTextColor,
  buttonHeight,
  buttonType,
}) => {
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Button
        disableElevation={true}
        type={buttonType}
        fullWidth
        disabled={isLoading || disabled}
        variant={buttonVariant}
        color={buttonColor}
        style={{
          height: buttonHeight || 'auto',
          margin: '16px 0px',
          color:
            loadingTextColor && isLoading ? loadingTextColor : color || 'white',
        }}
        onClick={onClickHandler}>
        {name}
      </Button>
      {isLoading && (
        <CircularProgress
          size={24}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
          }}
        />
      )}
    </div>
  );
};
