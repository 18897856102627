import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Row = ({ rowName, rowValue }) => {
  if (!rowValue) return <></>;
  else {
    return (
      <Typography>
        <span style={{ fontWeight: 500 }}>{rowName}: </span>
        {rowValue}
      </Typography>
    );
  }
};
const StationDescriptionContent = ({
  zip,
  address,
  city,
  state,
  coordinates,
  phone,
  openingHours,
  supplier,
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ marginBottom: '8px' }}>
      <Row rowName={t('pages.contactus.address')} rowValue={address} />
      <Row rowName={t('forms.city')} rowValue={city} />
      <Row rowName={t('driverDetailsForm.phone')} rowValue={phone} />
      <Row rowName={t('forms.zip')} rowValue={zip} />
      <Row rowName={t('common.openingHours')} rowValue={openingHours} />
    </div>
  );
};

export default StationDescriptionContent;
