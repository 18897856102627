import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  &.MuiButton-contained {
    border-radius: 4px;
    color: rgba(255, 255, 255);
    background: #14c2a9;
    // &:hover {
    //   background-color: #14c2a9;
    // }
  }
  max-width: 214px;
`;

const OrderButton = ({ isLoading, handleBookButtonClicked, carId, disableOrderButton }) => {
  const [buttonClicked, setButtonClicked] = React.useState(false);
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'flex-start'
      }}
    >
      <div
        style={{
          position: 'relative',
          // width: isMobile && '100%',
          display: 'flex',
          justifyContent: 'center',
          cursor: disableOrderButton ? 'not-allowed' : 'auto'
        }}
      >
        <StyledButton
          disableElevation
          $disableOrderButton={disableOrderButton}
          disabled={isLoading || buttonClicked || disableOrderButton}
          // onClick={() => handleBookButtonClicked(carId)}
          onClick={() => {
            setButtonClicked(true);
            setTimeout(() => handleBookButtonClicked(carId), 1000);
          }}
          style={{ height: isLoading && '32px', width: '200px' }}
          variant='contained'
          color='secondary'
          fullWidth
        >
          {!isLoading && t('carcard.book')}
        </StyledButton>
        {buttonClicked && (
          <CircularProgress
            size={24}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: -12,
              marginLeft: -12
            }}
          />
        )}
      </div>
    </div>
    // <StyledButton fullWidth variant='contained' color='secondary'>
    //   {t('common.order')}
    // </StyledButton>
  );
};

export default OrderButton;
