import SelectedIcon from './Components/SelectedIcon';
import { useState, useEffect } from 'react';
import { StyledPaper } from '../../styles/SortAndFiltersPanel.styled';
import { Grid, Typography } from '@material-ui/core';
import { Image } from './styles/CardFilter.styled';

const CardFilter = ({ image, selected, name, handleClassSelected }) => {
  const [elevation, setElevation] = useState(selected ? 0 : 2);
  const [mouseOver, setMouseOver] = useState(false);
  useEffect(() => {
    if (!selected) {
      if (elevation !== 2) {
        setElevation(2);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);
  return (
    <StyledPaper
      onMouseOver={() => {
        if (!selected) setElevation(5);
        setMouseOver(true);
      }}
      onMouseLeave={() => {
        if (!selected) setElevation(2);
        setMouseOver(false);
      }}
      onClick={() => {
        setElevation(0);
        handleClassSelected(name, selected);
      }}
      elevation={elevation}
      style={{
        height: 100,
        marginRight: 2,
        marginLeft: 2
      }}
    >
      <Grid container direction='column' justifyContent='space-between' style={{ padding: 4 , height:'100%'}}>
        <Grid item>
          {' '}
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <div style={{ width: 12, height: 12 }}></div>
            <Typography variant='body2' align='center' style={{ fontWeight: 600 }}>
              {name}
            </Typography>
            <SelectedIcon mouseOver={mouseOver} selected={selected} />
          </div>
        </Grid>
        <Grid item style={{ height: 50, padding: 4, marginTop: 'auto', }}>
          <Image selected={selected} src={image} alt='car_filter' width='100%' height='100%' />
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default CardFilter;
