import React from 'react';
import { StyledPaper } from '../styles/PaymentDetails.styled';
import {
  Accordion,
  AccordionSummary,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { currencyFormatter } from '../../../../../../../utils/currencyHelpers';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { StyledTooltip } from '../../../../../../ReusableComponents/StyledComponents';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const getExtraPrice = (extra, basePrice, currency, t) => {
  const { maxPrice, price, isPricePerRent } = extra;
  const { numberOfDays } = basePrice;
  if (price === -1) {
    return t('paymentDetails.priceAtDesk');
  }
  if (isPricePerRent) return currencyFormatter(currency.code, price);

  if (!maxPrice || maxPrice <= 0) {
    return currencyFormatter(currency.code, price * numberOfDays);
  } else {
    return currencyFormatter(
      currency.code,
      price * numberOfDays > maxPrice ? maxPrice : price * numberOfDays
    );
  }
};

const PaidLocally = ({
  summaryBackgroundColor,
  extras,
  requestedCar,
  currency,
  fees,
  getFeePrice,
}) => {
  const selectedExtras = extras.filter((extra) => extra.isChecked);
  const [expanded, setExpanded] = React.useState(true);
  const { basePrice } = requestedCar;
  const { t } = useTranslation();
  return (
    <>
      {(selectedExtras.length > 0 || fees.length > 0) && (
        <Accordion
          expanded={expanded}
          onChange={() => setExpanded((prevState) => !prevState)}
          style={{ marginTop: 4 }}>
          <AccordionSummary
            style={{ background: summaryBackgroundColor }}
            expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ fontWeight: 600 }}>
              {t('paymentDetails.additionalOptionsAndFees')} <br />(
              {t('common.payOnArrival')})
            </Typography>
          </AccordionSummary>
          {(selectedExtras.length > 0 || fees.length > 0) && (
            <StyledPaper elevation={0}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    {selectedExtras.map((extra) => {
                      return (
                        <TableRow key={extra.code}>
                          <TableCell>
                            {extra.name}{' '}
                            {extra.count > 1 ? `x${extra.count}` : ''}
                          </TableCell>

                          <TableCell align="right">
                            {getExtraPrice(extra, basePrice, currency, t)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {fees.map((fee, idx) => {
                      return (
                        <TableRow key={fee.code || idx}>
                          <TableCell
                            width="70%"
                            style={{ display: 'flex', alignItems: 'center' }}>
                            {fee.name}
                            {fee.description && (
                              <StyledTooltip
                                enterTouchDelay={0}
                                title={
                                  <Typography
                                    variant="body2"
                                    style={{ color: 'black' }}>
                                    {fee.description}
                                  </Typography>
                                }
                                arrow
                                style={{ color: 'black' }}>
                                <HelpOutlineIcon
                                  fontSize="small"
                                  style={{ marginLeft: 4 }}
                                />
                              </StyledTooltip>
                            )}{' '}
                          </TableCell>

                          <TableCell align="right" width="40%">
                            {getFeePrice(fee, currency.code)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableHead>
                </Table>
              </TableContainer>
            </StyledPaper>
          )}{' '}
        </Accordion>
      )}
    </>
  );
};

export default PaidLocally;
