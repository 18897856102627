import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ErrorServerResponseDialog = ({
  errorResponse: { value, message, stayOnPage },
  setErrorResponse,
}) => {
  const theme = useTheme();
  const [clicked, setClicked] = React.useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setErrorResponse({ value: false, message: '' });
    if (!stayOnPage) navigate('/');
  };

  React.useEffect(() => {
    if (clicked) {
      handleClose();
    }
  }, [clicked]);

  return (
    <Dialog onClose={handleClose} open={value}>
      <DialogTitle dir={theme.direction} onClose={handleClose}>
        {}
      </DialogTitle>
      <DialogContent dir={theme.direction} dividers>
        <Typography gutterBottom>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={clicked}
          autoFocus
          onClick={() => {
            setClicked(true);
          }}
          color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorServerResponseDialog;
