import { Paper } from '@material-ui/core';
import Slider from 'react-slick';
import styled from 'styled-components';

export const StyledSlider = styled(Slider)`
  .slick-list {
    padding-bottom: 1px;
  }
`;

export const StyledPaper = styled(Paper)`
  &:hover {
    border-bottom: 3px solid #50c2a9;
  }
`;
