import { Grid, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFuelTypeAndACBySippCode } from '../../../../../../../../../utils/utils';
import {
  CarName,
  IsModelGuaranteed,
  StyledTooltip,
  Subtitle,
} from './Title.styled';
import SippCodesTooltipContent from './Components/SippCodesTooltipContent';

const OrSimilarTitle = ({ t }) => {
  return (
    <Typography variant="body2" style={{ color: 'black' }}>
      {t('carcard.orsimilarTitle')}
    </Typography>
  );
};
const getTransmissionTranslationByType = (sipp_code, t) => {
  switch (sipp_code[2]) {
    case 'A':
      return t('carcard.carfeatures.automaticgear');
    case 'M':
      return t('carcard.carfeatures.manualgear');
    default:
      return '';
  }
};

const Title = ({
  transmission,
  carClass,
  carModel,
  sipp_code,
  year,
  isModelGuaranteed,
  fuel,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const {
    t,
    i18n: {
      languages: [lng],
    },
  } = useTranslation();
  const {
    direction,
    palette: {
      secondary: { main },
    },
  } = useTheme();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const { fuel: fuelType } = getFuelTypeAndACBySippCode(sipp_code, t);
  return (
    <Grid container direction="column">
      <Grid item>
        <CarName direction={direction}>{carModel}</CarName>
        <StyledTooltip title={<OrSimilarTitle t={t} />} arrow>
          <IsModelGuaranteed
            aria-describedby={id}
            onMouseLeave={() => setAnchorEl(null)}
            onMouseEnter={(e) => {
              setAnchorEl(anchorEl ? null : e.currentTarget);
            }}>
            {!isModelGuaranteed && t('carcard.orsimilar')}
          </IsModelGuaranteed>
        </StyledTooltip>
      </Grid>
      <Grid item>
        <Subtitle direction={direction}>
          {carClass} |{' '}
          <StyledTooltip
            interactive
            title={
              <SippCodesTooltipContent sipp_code={sipp_code} t={t} lng={lng} />
            }
            arrow>
            <span style={{ borderBottom: '1px dotted', cursor: 'help' }}>
              {sipp_code.slice(0, 4) || ''}
            </span>
          </StyledTooltip>{' '}
          | {getTransmissionTranslationByType(sipp_code, t)}{' '}
          {year && ' | ' + year}{' '}
          {fuel === 'electric' ||
          sipp_code[3] === 'E' ||
          sipp_code[3] === 'C' ? (
            <>
              <span> | </span>
              <span style={{ color: main, fontWeight: 600 }}>
                {t('carcard.carfeatures.electric')}
              </span>
            </>
          ) : (
            <>{fuelType && <span>| {fuelType}</span>}</>
          )}
        </Subtitle>
      </Grid>
    </Grid>
  );
};

export default Title;
