import {
  Box,
  Grid,
  Link,
  MenuItem,
  Select,
  Typography,
  TextField,
  Collapse,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { getPrice } from '../../../../../../utils/utils';
import CrossBorderExtra from './CrossBorderExtra';
import {
  StyledCheckBox,
  StyledFormControlLabel,
} from './styles/Insuranse.styled';

const Icon = styled.img`
  color: ${({ theme }) => theme.palette.primary.main};
  width: 40px;
  height: 40px;
`;
const numberOfOptions = (number) => {
  const optionsArray = [];
  for (let i = 0; i < number; i++) {
    optionsArray.push(
      <MenuItem key={i} value={i}>
        {i}
      </MenuItem>
    );
  }
  return optionsArray;
};

const WithLink = ({ isMobile, wrapper, children }) => {
  return isMobile ? wrapper(children) : children;
};

const Extra = ({
  handleCrossBorderSelected,
  icon,
  extra,
  supplier,
  isMobile,
  t,
  currencyCode,
  handleClickOpen,
  handleChange,
  handleSelect,
  description,
  name,
  apiType,
  handleUserCommentInput,
}) => {
  const needQuantity = extra.countable === undefined ? true : extra.countable;
  const {
    count,
    isChecked,
    price,
    code: value,
    maxPrice,
    isPricePerRent,
    required,
    isPayOnArrival,
    htmlDescription,
    maxCount,
  } = extra;
  const theme = useTheme();
  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          padding: '10px 16px 10px 10px',
          justifyContent: 'space-between',
        }}>
        <Grid container justifyContent="space-between">
          <Grid
            item
            sm={8}
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: isMobile ? '70px' : '50px',
            }}>
            <Box display="flex">
              <StyledFormControlLabel
                control={
                  <StyledCheckBox
                    checkboxcolor={theme.palette.secondary.main}
                    $disabledColor={theme.palette.secondary.main}
                    indeterminate={
                      value === 'CRB' && price === -1 && !isPayOnArrival
                    }
                    disabled={
                      required ||
                      value === 'APF' ||
                      value === 'UAD' ||
                      (price === 0 && value !== 'CRB') ||
                      (value === 'CRB' && price === -1 && !isPayOnArrival)
                    }
                    checked={
                      value === 'APF' ||
                      value === 'UAD' ||
                      (price === 0 && value !== 'CRB') ||
                      isChecked
                    }
                    value={value}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    onChange={handleChange(value)}
                  />
                }
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon src={icon} alt={icon} />
                    <div
                      style={{
                        margin: `0 ${
                          theme.direction === 'rtl' ? '10px' : 0
                        } 0 ${theme.direction === 'ltr' ? '10px' : 0}`,
                        display: 'flex',
                        flexDirection: 'column',
                      }}>
                      <WithLink
                        isMobile={isMobile}
                        children={<Typography>{name}</Typography>}
                        wrapper={(children) => <Link>{children}</Link>}
                      />
                      {price > 0 && (
                        <Typography variant="body2" >
                          {getPrice(
                            value,
                            currencyCode,
                            price,
                            count,
                            maxPrice,
                            t,
                            isPricePerRent,
                            true
                          )}
                        </Typography>
                      )}
                    </div>
                  </div>
                }
              />
            </Box>

            {value !== 'CRB' &&
              value !== 'APF' &&
              value !== 'UAD' &&
              price !== 0 &&
              maxCount > 2 &&
              needQuantity && (
                <Select
                  style={{ height: isMobile ? '70%' : '80%' }}
                  value={count}
                  onChange={handleSelect(value)}
                  variant="outlined">
                  {numberOfOptions(
                    maxCount
                      ? maxCount + 1
                      : value === 'GPS' || value === 'SCH'
                      ? 2
                      : 10
                  )}
                </Select>
              )}
          </Grid>
          {!isMobile && description && (
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Link
                onClick={() =>
                  handleClickOpen(name, description, htmlDescription)
                }
                style={{
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}>
                {t('protection.readMore')}
              </Link>
            </Grid>
          )}
          <Collapse
            in={isChecked && extra.needComment}
            style={{ width: '100%' }}>
            <Grid item xs={12} sm={8}>
              <Grid container justifyContent="space-between">
                <Grid
                  item
                  sm={8}
                  style={{ display: 'flex', alignItems: 'center' }}>
                  {extra.commentLabel}
                </Grid>
                <Grid item sm={4} style={{ paddingLeft: 8 }}>
                  <TextField
                    multiline
                    name={extra.code}
                    value={extra.userComment}
                    onChange={({ target: { name, value } }) =>
                      handleUserCommentInput(name, value)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>

      {value === 'CRB' && (price !== -1 || isPayOnArrival) && (
        <CrossBorderExtra
          handleSelected={handleCrossBorderSelected}
          checked={isChecked}
          currencyCode={currencyCode}
          CRBcountries={extra.countries}
          apiType={apiType}
          isPayOnArrival={extra.isPayOnArrival}
        />
      )}
    </>
  );
};

export default Extra;
