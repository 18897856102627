import React from 'react';
import { Grid, Container } from '@material-ui/core';
import BottomContactsBar from './BottomContactsBar';
import QuickLinks from './Components/QuickLinks/QuickLinks';
import Links from './Links';
import { SecureLogos } from './Components';
import { StyledDivider, StyledFooter } from './styles/Footer.styled';

const Footer = () => {
  return (
    <StyledFooter>
      <Container style={{ paddingBottom: 16 }}>
        <Grid container direction='column'>
          <QuickLinks />
          <StyledDivider />
          <Grid item style={{ paddingTop: 16 }}>
            <Grid container justifyContent='space-between' style={{ padding: '4px 0px' }}>
              <Grid item>
                <Links />
              </Grid>
              <Grid item>
                <SecureLogos />
              </Grid>
            </Grid>
          </Grid>
          <BottomContactsBar />
        </Grid>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
