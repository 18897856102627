import { Typography } from '@material-ui/core';

const Description = ({ description }) => {
  return (
    <>
      <Typography variant='body2' style={{ color: 'black' }}>
        {description}
      </Typography>
    </>
  );
};
export default Description;
