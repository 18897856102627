import { Card, CardMedia, Link, Select } from '@material-ui/core';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  margin-bottom: 32px;
`;

export const StyledSelect = styled(Select)`
  &.MuiSelect-outlined {
  }
`;
export const CarImage = styled(CardMedia)`
  &.MuiCardMedia-media {
    width: 100%;
    -moz-transform: ${({ $dir }) =>
      $dir === 'rtl' ? 'scaleX(-1)' : 'scaleX(1)'};
    -o-transform: ${({ $dir }) =>
      $dir === 'rtl' ? 'scaleX(-1)' : 'scaleX(1)'};
    -webkit-transform: ${({ $dir }) =>
      $dir === 'rtl' ? 'scaleX(-1)' : 'scaleX(1)'};
    transform: ${({ $dir }) => ($dir === 'rtl' ? 'scaleX(-1)' : 'scaleX(1)')};
    filter: FlipH;
    -ms-filter: 'FlipH';
  }
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
`;
export const StyledLink = styled(Link)`
  :hover {
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  }
`;
