import { LoadingItem, StyledCircularProgress } from './styles/CompanyCard.styled';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Card, Zoom } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { random } from 'lodash';

const CompanyCard = ({ company, name }) => {
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsloading] = useState(true);
  const [delay] = useState(random(200, 2000));

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress => (prevProgress >= 100 ? setIsloading(false) : prevProgress + 10));
    }, delay);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Card style={{ maxWidth: 70 }}>
      <LoadingItem>
        <div>
          <img src={company.logo} alt={name} width='100%' height='30px' />
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {isLoading ? (
            <StyledCircularProgress variant='determinate' value={progress} size={20} />
          ) : (
            <Zoom in={!isLoading}>
              <CheckCircleOutlineIcon color='secondary' />
            </Zoom>
          )}
        </div>
      </LoadingItem>
    </Card>
  );
};

export default CompanyCard;
