import React from 'react';
import { StyledChip } from './styles/EssentialReasonChips.styled';

const EssentialReasonChips = ({ reasons }) => {
  return (
    <>
      {reasons.map(reason => {
        return <StyledChip label={reason} />;
      })}
    </>
  );
};

export default EssentialReasonChips;
