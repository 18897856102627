import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import PricesAndTaxes from './Components/PricesAndTaxes';
import { Title } from './styles/PaymentDetails.styled';
import PaidLocally from './Components/PaidLocally';
import { useTranslation } from 'react-i18next';
import { currencyFormatter } from '../../../../../../utils/currencyHelpers';
import Policies from './Components/Policies';

const summaryBackgroundColor = '#e0e9eb';

const PaymentDetails = ({
  totalPrice,
  requestedCar,
  extras,
  insurances,
  currency,
  policies,
}) => {
  const { t } = useTranslation();
  const getFeePrice = (fee, currency) => {
    if (fee.isIncludedInPrice) {
      if (fee.price) {
        return (
          <>
            <Typography variant="body2">{t('common.Included')}</Typography> (
            <Typography component={'span'} variant="caption">
              {currencyFormatter(currency, fee.price)}
            </Typography>
            ){' '}
          </>
        );
      } else return t('common.Included');
    } else {
      return currencyFormatter(currency, fee.price);
    }
  };
  const sharedProps = {
    currency,
    summaryBackgroundColor,
    requestedCar,
    getFeePrice,
  };
  const { prices, basePrice } = requestedCar;
  const feesToPay =
    prices.fees && prices.fees.length > 0
      ? prices.fees.filter((fee) => !fee.isPayOnArrival)
      : [];
  const feesPayOnArrival =
    prices.fees && prices.fees.length > 0
      ? prices.fees.filter((fee) => fee.isPayOnArrival)
      : [];
  return (
    <>
      <Paper elevation={0} style={{ marginTop: 32 }}>
        <Title variant="h6">{t('paymentDetails.title')}</Title>
        <PricesAndTaxes
          basePrice={basePrice}
          fees={feesToPay}
          totalPrice={totalPrice}
          insurances={insurances}
          {...sharedProps}
        />
        <PaidLocally extras={extras} {...sharedProps} fees={feesPayOnArrival} />
        <Policies policies={policies} currency={currency} />
      </Paper>
    </>
  );
};

export default PaymentDetails;
