import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RUEuropcarIlRentalConditions, ENEuropcarIlRentalConditions } from './suppliers/europcar';

import SupplierRentalConditions from './suppliers/topRentACar/SupplierRentalConditions';

export default function RentalConditionsText({
  supplier: requestedSupplier,
  country: requestedCountry,
  apiType,
  ...rest
}) {

  const supplier = requestedSupplier.code ? requestedSupplier.code : requestedSupplier;
  const country = requestedCountry.code ? requestedCountry.code : requestedCountry;
  const {
    i18n: {
      languages: [lng]
    }
  } = useTranslation();

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  switch (country) {
    case 'il':
      if (supplier.toLowerCase() === 'europcar') {
        if (lng === 'ru') {
          return <RUEuropcarIlRentalConditions isMobile={isMobile} />;
        }
        if (lng === 'he') {
          return <SupplierRentalConditions {...rest} country='il' supplier={supplier} />;
        } else {
          return <ENEuropcarIlRentalConditions isMobile={isMobile} />;
        }
      }
      return <div>Didn't find</div>;

    default:
      return <SupplierRentalConditions {...rest} apiType={apiType} country={country} supplier={supplier} />;
  }
}
