import { Container, Grid } from '@material-ui/core';
import styled from 'styled-components';

export const StyledContainer = styled(Container)`
  display: flex !important;
  z-index: 1;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100%; */

  @media screen and (max-width: 600px) {
    &.MuiContainer-root {
      padding: 0px;
      justify-content: space-between;
    }
  }
`;

export const StyledSection = styled.section`
  background-size: cover;
  padding-top: ${({ ismobile }) => (!ismobile ? '180px' : '0px')};
  width: 100%;
  margin-bottom: ${({ ismobile }) => (ismobile ? '0px' : '140px')};
`;

export const PickerContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: ${({ ismobile }) => (ismobile ? '' : 'center')};
  margin-right: auto;
  padding: ${({ ismobile }) => (ismobile ? '4px' : 0)};
`;
