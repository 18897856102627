import { Typography, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { displayPriceWithCurrency } from '../../../../../utils';
import { getPeriodTitle } from '../../CarCardUtils';
import { PerDay } from './CarFeatures/styles/PricePerPeriod.styled';
import { getDisplayPriceWithVat } from '../../../../../utils/utils';

const PriceWrapper = ({ isMobile, pricePerPeriod, children }) => {
  return isMobile ? (
    <Typography
      style={{ fontWeight: 600, marginRight: 4 }}
      component="span"
      variant="body2">
      {children}
    </Typography>
  ) : (
    <span style={{ fontSize: pricePerPeriod === -1 ? 39 : 40 }}>{children}</span>
  );
};

const PricePerPeriod = ({
  lng,
  country,
  currencyCode,
  isLoading,
  basePrice,
  isMobileCard,
  direction,
  vatLevel,
}) => {
  const { price, pricePerPeriod, period, periodType, VATIncluded } =
    basePrice || {};
  const { t } = useTranslation();
  const displayPrice = getDisplayPriceWithVat(
    price ? price : pricePerPeriod,
    VATIncluded,
    vatLevel
  );
  return (
    <div>
      {isLoading ? (
        <Skeleton variant="rect" height={50} />
      ) : (
        <PriceWrapper isMobile={isMobileCard} pricePerPeriod={pricePerPeriod}>
          {pricePerPeriod !== -1
            ? displayPriceWithCurrency(undefined, displayPrice, currencyCode, t)
            : t('carcard.comingSoon')}
        </PriceWrapper>
      )}
      {isMobileCard ? (
        isLoading ? (
          <Skeleton variant="text" />
        ) : (
          <>
            {pricePerPeriod !== -1 && (
              <Typography component="span" variant="body2">
                {periodType || ' ' + t('carcard.priceperday')}
              </Typography>
            )}
          </>
        )
      ) : (
        ''
      )}
      {!isMobileCard && pricePerPeriod !== -1 && (
        <PerDay $direction={direction}>
          {isLoading ? (
            <Skeleton variant="text" />
          ) : (
            periodType || t('carcard.priceperday')
          )}
        </PerDay>
      )}
    </div>
  );
};

export default PricePerPeriod;
