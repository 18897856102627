import './App.css';
import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import BackToTopButton from './components/Layouts/BackToTopButton/BackToTopButton';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga4';
import Routes from './components/Routes/Routes';
import { useTranslation } from 'react-i18next';
import { Store, CurrencyContext } from './components/SharedState';
import TagManager from 'react-gtm-module';
import CookieConsentBanner from './components/CookieConsentBanner/CookieConsentBanner';
import ScrollToTop from './components/HelperComponents/ScrollToTop';
import { SEOdata } from './utils';
import ChatBox from './components/Routes/Home/SecondSection/Components/ChatBox/ChatBox2';
import QuickRentButton from './components/Routes/Home/SecondSection/Components/QuickRentButton';

ReactGA.initialize(process.env.REACT_APP_MEASURMENT_ID);

const tagManagerArgs = {
  gtmId: 'GTM-M8FL5JP',
};
TagManager.initialize(tagManagerArgs);
const App = () => {
  window.dataLayer.push({
    event: 'pageview',
  });
  const {
    t,
    i18n: {
      languages: [lng],
    },
  } = useTranslation();
  const [currency, setCurrency] = useState({
    code: 'USD',
    sign: '$',
    icon: <FontAwesomeIcon style={{ fontSize: '20px' }} icon={faEuroSign} />,
  });

  if (window.location.pathname === '/') {
    window.location.replace(`${lng}`);
  }

  return (
    <>
      <SEOdata
        type="website"
        title={t('metatags.mainPage.titleWithoutLocation')}
        lng={lng}
        description={t('metatags.mainPage.descriptionWithoutLocation')}
      />

      <div className="App" dir={lng === 'he' ? 'rtl' : 'ltr'}>
        <BrowserRouter basename={`/${lng}`}>
          <ScrollToTop />
          <CookieConsentBanner />
          <Store>
            <CurrencyContext.Provider value={[currency, setCurrency]}>
              <Routes />
            </CurrencyContext.Provider>
            <BackToTopButton />
          </Store>
        </BrowserRouter>
        {/* <QuickRentButton /> */}
        <ChatBox lng={lng} />
      </div>
    </>
  );
};

export default App;
