import { TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTooltip } from '../../../../../../ReusableComponents/StyledComponents';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const FeesRow = ({ fees, currency }) => {
  const { t } = useTranslation();
  const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency,
  });
  const getFeePrice = (price, isIncludedInPrice, hasToBeDisplayed) => {
    if (isIncludedInPrice || price === 0) {
      return t('common.Included');
    } else return currencyFormatter.format(price);
  };
  return (
    <>
      {fees.map((fee) => {
        return (
          <TableRow>
            <TableCell component="th" scope="row" style={{display:'flex', alignItems:'center'}}>
              {fee.name}
              {fee.description && (
                <StyledTooltip
                  enterTouchDelay={0}
                  title={
                    <Typography variant="body2" style={{ color: 'black' }}>
                      {fee.description}
                    </Typography>
                  }
                  arrow
                  style={{ color: 'black' }}>
                  <HelpOutlineIcon fontSize="small" style={{ marginLeft: 4 }} />
                </StyledTooltip>
              )}
            </TableCell>
            <TableCell align="right">
              {getFeePrice(
                fee.price,
                fee.isIncludedInPrice,
                fee.hasToBeDisplayed
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

export default FeesRow;
