import React from 'react';
import {
  Accordion,
  AccordionDetails,
  Grid,
  Icon,
  ListItemIcon,
  Typography,
  useTheme
} from '@material-ui/core';
// import checkSign from './Icons/checkSign.svg';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import checkSign from './Icons/check.svg';
import { StyledTooltip } from '../../LeftSection/Components/Title/Title.styled';
import { StyledAccordionSummary, Title } from './styles/Included.styled';

const CheckSignIcon = () => (
  <ListItemIcon style={{ minWidth: 14 }}>
    <Icon style={{ fontSize: 'inherit', padding: 4 }}>
      <img src={checkSign} width={14} alt={'✓'} />
    </Icon>
  </ListItemIcon>
);
const ListOfItems = ({ items, isSummary, direction }) => {
  return (
    <ul
      // dense={true}
      style={{
        paddingBottom: isSummary && 0,
        paddingTop: !isSummary && 0,
        listStyleType: 'none',
        margin: 0,
        padding: 0
      }}
    >
      {items.map((item, idx) => {
        return (
          <li key={idx} style={{ paddingLeft: 0 }}>
            {/* <FontAwesomeIcon icon={faCheck} />  */}
            <CheckSignIcon />
            <Typography component='span' style={{ fontSize: 13 }}>
              {typeof item === 'object' ? (
                <StyledTooltip
                  $direction={direction}
                  $color={'#000000DE'}
                  title={<Typography variant='body2'>{item.description}</Typography>}
                  arrow
                >
                  <span style={{ cursor: 'help', borderBottom: '1px dashed' }}>{item.name}</span>
                </StyledTooltip>
              ) : (
                item
              )}
            </Typography>
          </li>
        );
      })}
    </ul>
  );
};

const Included = ({ VATIncluded, protections, period, isLoading, included, isPC }) => {
  const { t } = useTranslation();
  const { direction } = useTheme();
  const getIncludedItems = () => {
    if (included) {
      return included;
    }
    const includedItems = [];

    if (protections) {
      protections.forEach(protection => {
        if (protection.isIncludedInPrice || protection.price === 0) includedItems.push(protection.code);
      });
      if (VATIncluded) {
        includedItems.push(t('common.vat'));
      }
    }
    return includedItems;
  };

  const includedItems = getIncludedItems();
  return (
    <Grid container direction='column' alignContent='flex-start'>
      <>
        {' '}
        <Grid item>
          {isPC && (
            <Title>
              {isLoading ? (
                <Skeleton variant='rect' height={16} width={200} />
              ) : includedItems.length > 0 ? (
                t('common.Included')
              ) : (
                ''
              )}
            </Title>
          )}
        </Grid>
        <Grid item>
          {isPC && includedItems.length > 0 && includedItems.length > 8 ? (
            <Accordion elevation={0}>
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} style={{ padding: 0, margin: 0 }}>
                {protections && (
                  <ListOfItems direction={direction} items={includedItems.slice(0, 8)} isSummary={true} />
                )}
              </StyledAccordionSummary>
              <AccordionDetails
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 0,
                  marginBottom: 8
                }}
              >
                {protections && (
                  <ListOfItems direction={direction} items={includedItems.slice(8)} isSummary={false} />
                )}
              </AccordionDetails>
            </Accordion>
          ) : (
            <ListOfItems items={includedItems} isSummary={false} direction={direction} />
          )}
        </Grid>
      </>
    </Grid>
  );
};

export default Included;
