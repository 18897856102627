import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CarFeatures from '../CardComponents/CarFeatures/CarFeatures';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import PriceSection from './Components/PriceSection';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { OrderButtonGrid, PriceSectionGrid } from './styles/MobileCard.styled';
import Title from './Components/Title/Title';
const Included = React.lazy(() => import('../PCCarCard/Components/RightSection/Components/Included'));

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%',
    marginBottom: '16px'
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    // backgroundColor: theme.palette.primary.main
  }
}));

export default function MobileCard({
  sipp_code,
  avatarLabel,
  image,
  carModel,
  supplierImage,
  supplier,
  country,
  carFeaturesProps,
  basePrice,
  currencyCode,
  carLocation,
  numberOfDays,
  isLoading,
  handleBookButtonClicked,
  carId,
  isModelGuaranteed,
  year,
  prices
}) {
  const { protection_coverage: protections, included } = prices || {};
  const { VATIncluded, period } = basePrice || {};
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const {
    t,
    i18n: {
      languages: [lng]
    }
  } = useTranslation();
  const [buttonClicked, setButtonClicked] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const WrappedTitle = () => {
    return (
      <Title
        sipp_code={sipp_code}
        isLoading={isLoading}
        carModel={carModel}
        isModelGuaranteed={isModelGuaranteed}
        year={year}
        t={t}
      />
    );
  };
  return (
    <Card className={classes.root} elevation={3}>
      <CardHeader component={WrappedTitle} />
      <Grid container spacing={1}>
        <Grid item xs={9} style={{ padding: 16 }}>
          {isLoading ? (
            <Skeleton animation='wave' variant='rect' height={200} width='100%' />
          ) : (
            <CardMedia
              className={classes.media}
              image={image}
              title={carModel}
              style={{ backgroundSize: 'contain' }}
            />
          )}
        </Grid>
        <Grid item xs={3} style={{ textAlign: 'center' }}>
          {<CarFeatures {...carFeaturesProps} isLoading={isLoading} sippCode={sipp_code} />}
        </Grid>
      </Grid>
      <CardContent>
        <Grid container spacing={1} justifyContent='space-between'>
          <PriceSectionGrid item xs={6}>
            {isLoading ? (
              <>
                <Skeleton animation='wave' variant='text' />
                <Skeleton animation='wave' variant='text' />
              </>
            ) : (
              <PriceSection
                isMobile={true}
                isLoading={isLoading}
                country={country}
                numberOfDays={numberOfDays}
                t={t}
                currencyCode={currencyCode}
                lng={lng}
                basePrice={basePrice}
              />
            )}
          </PriceSectionGrid>
          <OrderButtonGrid xs={6} item>
            <div
              style={{
                position: 'relative',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                disableElevation={true}
                disabled={isLoading || buttonClicked || basePrice.pricePerPeriod === -1}
                // onClick={() => handleBookButtonClicked(carId)}

                onClick={() => {
                  setButtonClicked(true);
                  setTimeout(() => handleBookButtonClicked(carId), 1000);
                }}
                style={{ height: isLoading && '32px', width: '100%', color: 'white' }}
                variant='contained'
                color='secondary'
                fullWidth
              >
                {!isLoading && t('carcard.book')}
              </Button>
              {buttonClicked && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12
                  }}
                />
              )}
            </div>
          </OrderButtonGrid>
        </Grid>
      </CardContent>
      <CardActions style={{ display: 'flex', justifyContent: 'space-between' }} disableSpacing>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item>
            {isLoading ? (
              <Skeleton variant='rect' animation='wave' width={100} height={50} />
            ) : (
              <>
                {supplier && supplier.image && (
                  <img style={{ maxHeight: 24 }} src={supplier.image} alt={supplier.name} />
                )}
              </>
            )}
          </Grid>
          <Grid item onClick={handleExpandClick}>
            {isLoading ? (
              <Skeleton variant='text' animation='wave' width={100} style={{ display: 'inline-block' }} />
            ) : (
              t('carcard.yourCarRentalIncludes')
            )}
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              aria-expanded={expanded}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardActions>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>
          <React.Suspense fallback={<div></div>}>
            <Included
              VATIncluded={VATIncluded}
              protections={protections}
              period={period}
              isLoading={isLoading}
              included={included}
              isPC={false}
            />
          </React.Suspense>
        </CardContent>
      </Collapse>
    </Card>
  );
}
