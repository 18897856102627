import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SaturdayDrivingCheckBox = ({ checked, handleSaturdayDrivingChecked }) => {
  const { t } = useTranslation();
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox checked={checked} onChange={e => handleSaturdayDrivingChecked(e.target.checked)} />
        }
        label={t('picker.carpicker.saturdayDrive')}
      />
    </FormGroup>
  );
};

export default SaturdayDrivingCheckBox;
