import { AccordionSummary } from "@material-ui/core";
import styled from "styled-components";

export const Title = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 23px;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
    padding: 0;
  }
`;