import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import axios from 'axios';
// import { useTranslation } from 'react-i18next';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
const LocationMap = ({
  isMobile,
  coordinates: locationCoordinates,
  location,
  zip,
  address,
  city,
  state,
  locationName,
  title,
  supplier
}) => {
  // const {
  //   i18n: {
  //     languages: [lng]
  //   }
  // } = useTranslation();
  const [coordinates, setCoordinates] = React.useState([]);

  React.useEffect(() => {
    if (!locationCoordinates) {
      let url = encodeURI(
        `https://nominatim.openstreetmap.org/search?q=${address.replace(',', ' ')} ${city}&format=geocodejson`
      );
      // console.log('URL', url);
      axios
        .get(url)
        .then(res => {
          // console.log('RESULT', res.data);
          if (res.data.features[0]) {
            const loc = res.data.features[0].geometry.coordinates;
            setCoordinates([loc[1], loc[0]]);
          } else {
            url = encodeURI(
              `https://nominatim.openstreetmap.org/search?q=${city} ${locationName}&format=geocodejson`
            );
            axios
              .get(url)
              .then(res => {
                if (res.data.features[0]) {
                  const loc = res.data.features[0].geometry.coordinates;
                  setCoordinates([loc[1], loc[0]]);
                }
              })
              .catch(err => console.error(err));
          }
        })
        .catch(err => console.error(err));
    } else setCoordinates([locationCoordinates.latitude, locationCoordinates.longitude]);
  }, []);
  // React.useEffect(() => {
  //   console.log(coordinates);
  //   console.log('LENGTH', coordinates.length > 0);
  // }, [coordinates]);
  return (
    <>
      {coordinates.length > 0 && (
        <MapContainer
          style={{ height: 400, width: isMobile ? '100%' : 400, border: '1px solid #BDBDBD' }}
          center={coordinates}
          zoom={14}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          <Marker position={coordinates}>
            <Popup>
              {supplier ? (
                <>
                  {supplier} <br />
                </>
              ) : (
                <></>
              )}

              {title}
            </Popup>
          </Marker>
        </MapContainer>
      )}{' '}
    </>
  );
};

export default LocationMap;
