import { Tooltip, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const LI = styled.li`
  display: flex;
  justify-items: center;
`;
// export const StyledItemIcon = styled(CreditCardIcon)`
//   color: #606164;
//   font-size: 22px;
// `;

export const List = styled.ul`
  margin: 0;
  padding: 0;
`;

export const ItemTitle = styled(Typography)`
  border-bottom: 1px dashed;
  cursor: ${({ underline }) => (underline ? 'help' : 'initial')};
`;

export const StyledTooltip = styled(props => {
  return <Tooltip enterTouchDelay={0} classes={{ popper: props.className }} {...props} />;
})`
  .MuiTooltip-tooltip {
    display: flex;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0px 0px 24px #00000034;
  }
  .MuiTooltip-arrow {
    &:before {
      background-color: white;
    }
`;
