import React from 'react';
import Header from '../Header/Header';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { CurrencyContext } from '../../SharedState';
import { BackGroundImageContext } from '../../SharedState/SearchState';
import { StyledApp } from './MainLayout.styled';
const Footer = React.lazy(() => import('../Footer/Footer'));

const MainLayout = ({ children, withoutBackground, isTransparent }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [backgroundImage] = React.useContext(BackGroundImageContext);
  //is mobile
  const [currency, setCurrency] = React.useState({
    code: 'USD',
    sign: '$',
    icon: <FontAwesomeIcon style={{ fontSize: '20px' }} icon={faEuroSign} />
  });
  return (
    <StyledApp
      withoutBackground={withoutBackground}
      ismobile={isMobile}
      style={{ position: 'relative' }}
      backgroundImage={backgroundImage}
    >
      {!isMobile && !withoutBackground && (
        <img
          src='https://i.imgur.com/GHcBSUb.png'
          style={{ opacity: 0.7, position: 'absolute', left: 0, top: 0, width: '100%' }}
          alt='cover'
        />
      )}
      <CurrencyContext.Provider value={[currency.icon, setCurrency]}>
        <Header
          position='relative'
          headerWithBackground={withoutBackground}
          isTransparent={isTransparent !== undefined ? isTransparent : true}
        />
      </CurrencyContext.Provider>
      <div style={{ position: 'relative' }}>{children}</div>
      <React.Suspense fallback={<div></div>}>
        <Footer />
      </React.Suspense>
    </StyledApp>
  );
};

export default MainLayout;
