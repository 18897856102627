import React, { useState } from 'react';
import CarCard from '../../Layouts/CarCard/CarCard';
import { getCarClassBySippCode } from '../../../utils/';
import { Grid, Typography } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import './styles/style.css'


const Results = ({
  carLocation,
  handleBookButtonClicked,
  searchResult,
  isLoading,
  numberOfDays,
  country,
}) => {
  const { t } = useTranslation();
  const [cars, setCars] = useState(isLoading ? [] : searchResult);
  const [displayCars, setDisplayCars] = useState(cars.slice(0, 20));
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setDisplayCars(cars.slice(0, 20));
    if (!hasMore) {
      setHasMore(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cars]);

  useEffect(() => {
    setCars(searchResult);
  }, [searchResult]);

  const getMore = () => {
    if (cars.length === displayCars.length) {
      setHasMore(false);
    }
    setTimeout(() => {
      setDisplayCars(cars.slice(0, displayCars.length + 10));
    }, 1500);
  };

  return (
    <Grid container className="infinite-scroll-component__outerdiv" style={{width:'100%'}}>
      <InfiniteScroll
        dataLength={displayCars.length}
        loader={
          <Grid item xs={12} style={{ padding: 8 }}>
            <Typography style={{ textAlign: 'center' }}>
              {t('common.loading')}
            </Typography>
          </Grid>
        }
        hasMore={hasMore}
        next={getMore}>
        {displayCars.map((car, idx) => {
          const { carId, protection_coverage, carclass, ...rest } = car;
          return (
            <Grid item key={idx}>
              <CarCard
                // reservationInformation={car.reservationInformation}
                // apiType={car.apiType}
                protections={protection_coverage}
                numberOfDays={car.basePrice.numberOfDays}
                isLoading={isLoading}
                handleBookButtonClicked={() => {
                  handleBookButtonClicked(car.carId);
                }}
                carClass={
                  carclass ||
                  getCarClassBySippCode(car.sipp_code, car.car_group, t)
                }
                carModel={car.vehicle_type}
                carLocation={carLocation}
                country={country}
                {...rest}
              />
            </Grid>
          );
        })}
      </InfiniteScroll>
    </Grid>
  );
};

export default Results;
