import { SpeedDial, SpeedDialAction } from '@material-ui/lab';
import React from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import TelegramIcon from '@material-ui/icons/Telegram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ChatIcon from '@material-ui/icons/Chat';
import useScroll from '../../../../../utils/hooks/useScroll';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { StyledSpeedDial } from '../styles/QuickRentButton';

const actions = [
  { icon: <WhatsAppIcon />, name: 'WhatsApp' },
  { icon: <TelegramIcon />, name: 'Telegram' },
  { icon: <FacebookIcon />, name: 'Facebook' }
];


const QuickRentButton = () => {
  const theme = useTheme();
  const ismobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const { crossed } = useScroll(200);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const openNewWindow = url => {
    window.open(url, '_blank').focus();
  };
  const handleClicked = action => {
    switch (action) {
      case 'WhatsApp':
        openNewWindow(`https://wa.me/972542041614?text=${t('chat.whatsapp')}`);
        break;
      case 'Telegram':
        openNewWindow('https://t.me/We4Rent');
        break;
      case 'Facebook':
        openNewWindow('http://m.me/we4rent');
        break;
      default:
        return;
    }
  };
  return (
    <StyledSpeedDial
      ismobile={ismobile ? 1 : 0}
      ariaLabel='SpeedDial'
      direction={crossed ? 'up' : 'left'}
      icon={<ChatIcon style={{ color: 'white' }} />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      FabProps={{ color: 'secondary' }}
      crossed={crossed ? 1 : 0}
    >
      {actions.map(action => (
        <SpeedDialAction
          id={action.name}
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={() => handleClicked(action.name)}
        />
      ))}
    </StyledSpeedDial>
  );
};

export default QuickRentButton;
