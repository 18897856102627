import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Grid, Hidden } from '@material-ui/core';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import CarGroupAppliedFilter from './Components/CarGroupAppliedFilter';
import { StyledSlider } from './styles/SortAndFiltersPanel.styled';
import Arrow from './Components/Arrow';
import CardFilter from './Components/CardFilter/CardFilter';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { StyledChip } from './Components/CardFilter/styles/CardFilter.styled';
import TuneIcon from '@material-ui/icons/Tune';
import { useTranslation } from 'react-i18next';

const SortAndFiltersPanel = ({
  setFilteDialogOpen,
  gearBoxes,
  setGearBoxes,
  carsClasses,
  setCarsClasses,
  checkState,
  setCheckState,
  MileageChecked: { Limited, Unlimited },
  handleSortByPrice,
  setMileageChecked,
  setGearBoxChecked,
  t,
  handleDelete,
  isMobile,
  fuelTypes,
}) => {
  const {
    i18n: {
      languages: [lng],
    },
  } = useTranslation();
  const appliedFilters = carsClasses.filter((carClass) => carClass.isChecked);
  const gearBoxesToDisplay = gearBoxes.filter((gb) => gb.isChecked);
  const isDisplayTitle =
    gearBoxesToDisplay.length > 0 ||
    Unlimited ||
    (appliedFilters && appliedFilters.length > 0);

  const handleDeleteGearBox = (name) => {
    const tempGearBoxes = [...gearBoxes];
    const idx = tempGearBoxes.findIndex((gb) => gb.type === name);
    if (idx > -1) {
      tempGearBoxes[idx].isChecked = false;
    }
    setGearBoxes(tempGearBoxes);
  };
  const slider = React.useRef(null);

  const settings = {
    adaptiveHeight: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: carsClasses.length <= 5 ? carsClasses.length : 5,
    rows: 1,
    slidesToScroll: 3,
    prevArrow: <Arrow isBack={lng !== 'he'} />,
    nextArrow: <Arrow isBack={lng === 'he'} />,
  };
  const handleClassSelected = (carClass, selected) => {
    const tempClasses = [...carsClasses];
    const idx = tempClasses.findIndex((cc) => {
      return cc.carClass === carClass;
    });
    if (idx > -1) {
      tempClasses[idx].isChecked = !selected;
      setCarsClasses(tempClasses);
    }
  };
  return (
    <>
      {!isMobile && (
        <Container>
          <StyledSlider
            ref={slider}
            {...settings}
            style={{
              height: '100px',
            }}>
            {carsClasses.map((cc) => {
              return (
                <div key={cc.carClass}>
                  <CardFilter
                    selected={cc.isChecked}
                    name={cc.carClass}
                    image={cc.image}
                    handleClassSelected={handleClassSelected}
                  />
                </div>
              );
            })}
          </StyledSlider>
        </Container>
      )}
      <Grid
        container
        style={{ padding: 4, margin: '8px 0px' }}
        alignItems="center"
        justifyContent="space-between">
        {!isMobile && (
          <Grid item lg={8}>
            {isDisplayTitle && (
              <Grid container spacing={1} alignItems="center">
                <Grid item>{t('filters.filtersApplied')}:</Grid>
                <CarGroupAppliedFilter
                  appliedFilters={appliedFilters}
                  carsClasses={carsClasses}
                  setCarsClasses={setCarsClasses}
                />
                {gearBoxesToDisplay.map((gb) => {
                  return (
                    gb.isChecked && (
                      <StyledChip
                        variant="outlined"
                        label={gb.type}
                        onDelete={() => handleDeleteGearBox(gb.type)}
                      />
                    )
                  );
                })}
                {Limited && (
                  <Grid item>
                    <StyledChip
                      variant="outlined"
                      label="Limited Mileage"
                      onDelete={() =>
                        setMileageChecked({ ...Unlimited, Limited: false })
                      }
                    />
                  </Grid>
                )}
                {Unlimited && (
                  <Grid item>
                    <StyledChip
                      variant="outlined"
                      label="Unlimited Mileage"
                      onDelete={() =>
                        setMileageChecked({ ...Limited, Unlimited: false })
                      }
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}

        {/**Filter button for mobile version only */}

        <Hidden smUp>
          <Button onClick={() => setFilteDialogOpen(true)}>
            <TuneIcon /> {t('leftPane.filter')}
          </Button>
        </Hidden>

        {/* Sort By Price  */}
        <Grid item style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Button
            // variant='contained'
            onClick={handleSortByPrice}>
            <FontAwesomeIcon style={{ marginRight: '4px' }} icon={faSort} />
            {t('common.sortByPrice')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default SortAndFiltersPanel;
