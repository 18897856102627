import {
  Button,
  ClickAwayListener,
  Fade,
  Grid,
  Paper,
  Popper,
  TextField,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { ButtonWithLoader } from '../../../../../../ReusableComponents/ButtonWithLoader';
import { useFormik } from 'formik';
import { agentLogin, validateForm } from './helpers';
import { FormField } from '../style/AgentMenuItem.styled';
import { AgentContext } from '../../../../../../SharedState/SearchState';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';

const AgentMenuItem = ({ anchorRef, handleListKeyDown, open, setOpen }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [agent, setAgent] = useContext(AgentContext);
  const handleAgentLogout = () => {
    setOpen(false);
    setTimeout(() => {
      setAgent(null);
    }, 1000);
  };

  const handleSubmit = async ({ email, password }) => {
    setIsLoading(true);
    const { success, error, accessToken, refreshToken } = await agentLogin(
      email,
      password
    );
    if (!success) {
      if (error) {
        alert(error);
      }
    } else {
      setOpen(false);
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      const { name, agentId, email } = jwtDecode(accessToken);
      setTimeout(() => {
        setAgent({ name, agentId, email, accessToken, refreshToken });
      }, 1000);
    }
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: validateForm,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  // useEffect(() => {
  //   console.log(agent);
  // }, [agent]);
  return (
    <Popper
      modifiers={{
        arrow: {
          enabled: true,
          // element: arrowRef
        },
      }}
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-end">
      {({ TransitionProps, placement }) => (
        <Fade
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}>
          <Paper style={{ padding: '8px 16px' }}>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              {agent ? (
                <>
                  <Button aria-label="Agent login" role="menuitem" onClick={handleAgentLogout}>
                    {t('buttons.logout')}
                  </Button>
                </>
              ) : (
                <form onSubmit={formik.handleSubmit}>
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                    autoFocusItem={open}
                    onKeyDown={handleListKeyDown}>
                    <FormField item>
                      <TextField
                        fullWidth
                        error={formik.touched && Boolean(formik.errors.email)}
                        helperText={formik.touched && formik.errors.email}
                        disabled={isLoading}
                        id="email"
                        type="email"
                        label="Email"
                        onChange={formik.handleChange}
                        margin="dense"
                        variant="outlined"
                        style={{ marginTop: 4 }}
                      />
                    </FormField>
                    <FormField item>
                      <TextField
                        error={
                          formik.touched && Boolean(formik.errors.password)
                        }
                        helperText={formik.touched && formik.errors.password}
                        onChange={formik.handleChange}
                        type="password"
                        id="password"
                        disabled={isLoading}
                        margin="dense"
                        style={{ marginTop: 4 }}
                        label={t('forms.password')}
                        variant="outlined"
                      />
                    </FormField>
                    <Grid
                      item
                      style={{ display: 'flex', alignItems: 'center' }}>
                      <ButtonWithLoader
                        buttonType={'submit'}
                        loadingTextColor="#fff"
                        disabled={isLoading}
                        onClickHandler={formik.onsubmit}
                        isLoading={isLoading}
                        buttonHeight={40}
                        buttonVariant="outlined"
                        color="rgb(0, 0, 0,0.87)"
                        name={t('forms.login')}
                      />
                    </Grid>
                  </Grid>
                </form>
              )}
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default AgentMenuItem;
