import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MainLayout, SecondaryLayout } from '../Layouts';
import Main from './Home/Main';

import RouteWithLayout from './RouteWithLayout';
import StaticPage from '../Layouts/StaticPageLayout/StaticPage';
import TransparentHeader from '../Layouts/TransparentHeaderLayout/TransparentHeader';

import Order from './SearchResults/Order';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Redirect from './SearchResults/OrderSummary/Components/RoutesComponents/Redirect';

const AllCommonArticles = lazy(() => import('./Home/CommonArticle/AllCommonArticles'));
const AllCountries = lazy(() => import('./AllCountries/AllCountries'));
const CommonArticle = lazy(() => import('./Home/CommonArticle/CommonArticle'));
const ArticleLayout = lazy(() => import('../Layouts/ArticleLayout/ArticleLayout'));
const LocationArticle = lazy(() => import('./LocationArticle/LocationArticle'));
const PrivacyPolicy = lazy(() => import('../StaticPages/PrivacyPolicy/PrivacyPolicy'));
const CookiePolicy = lazy(() => import('../StaticPages/CookiePolicy/CookiePolicy'));
const FormRentalLandingPage = lazy(() =>
  import('./PromotionalPages/RentalApartments/components/FormRentalLandingPage')
);

const SimCardsLandingPage = lazy(() =>
  import('./PromotionalPages/SimPromotional/LandingPage/SimCardsLandingPage')
);
const RentalApartmentsLandingPage = lazy(() =>
  import('./PromotionalPages/RentalApartments/RentalApartmentsLandingPage')
);
// const Order = lazy(() => import('./SearchResults/Order'));
const SuccessPage = lazy(() => import('./SuccessPage/SuccessPage'));
const SupplierRentalConditions = lazy(() => import('./RentalConditions/SupplierRentalConditions'));
const AboutCity = lazy(() => import('./AboutCity'));
const AboutCompany = lazy(() => import('./AboutCompanies/AboutCompany'));
const FAQContent = lazy(() => import('./FAQ/Components/FAQContent'));
const Fleet = lazy(() => import('./Fleet/Fleet'));
const CarCategoryDescription = lazy(() =>
  import('./Fleet/Components/CarCategoryDescription/CarCategoryDescription')
);
const TermsOfUseContent = lazy(() => import('./TermsOfUse/Components/TermsOfUseContent'));
const PageNotFound = lazy(() => import('./404/PageNotFound'));
const Contact = lazy(() => import('./Contact/Contact'));
const AboutUs = lazy(() => import('../StaticPages/AboutUs/AboutUs'));
const BlogMain = lazy(() => import('./Blog/BlogMain/BlogMain'));
const Article = lazy(() => import('./Blog/Article'));
const Reservations = lazy(() => import('./Reservations/Reservations'));

const RouterRoutes = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const ismobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route
            path='/'
            element={
              <RouteWithLayout
                layout={MainLayout}
                isTransparent={!ismobile}
                component={Main}
                path='/'
                // reviews={reviews.reviews}
              />
            }
          />
          <Route
            path='/articles'
            element={<RouteWithLayout layout={SecondaryLayout} component={AllCommonArticles} />}
          />
          <Route
            path='/country/:country/:location?'
            element={
              <RouteWithLayout
                layout={MainLayout}
                isTransparent={!ismobile}
                component={Main}
                // reviews={reviews.reviews}
              />
            }
          />

          <Route path='/blog' element={<RouteWithLayout layout={SecondaryLayout} component={BlogMain} />} />
          <Route
            path='/blog/:article'
            element={<RouteWithLayout layout={ArticleLayout} component={Article} />}
          />
          <Route
            path='/fleet/:carcategory'
            element={
              <RouteWithLayout
                layout={MainLayout}
                isTransparent={false}
                withoutBackground={true}
                component={CarCategoryDescription}
              />
            }
          />
          <Route
            path='/privacy-policy'
            element={<RouteWithLayout layout={StaticPage} component={PrivacyPolicy} />}
          />
          <Route
            path='/cookie-policy'
            element={<RouteWithLayout layout={StaticPage} component={CookiePolicy} />}
          />
          <Route
            path='/partners/:company/name/:companyname'
            element={<RouteWithLayout layout={StaticPage} component={AboutCompany} />}
          />
          <Route
            path='/:country/aboutplace/:city'
            element={<Redirect to={`/:country/populardestinations/:city`} />}
          />
          <Route
            path='/:country/populardestinations/:city'
            element={<RouteWithLayout layout={SecondaryLayout} component={AboutCity} />}
          />
          <Route
            path='/country/:country/articles/:article'
            element={<RouteWithLayout layout={ArticleLayout} component={LocationArticle} />}
          />
          <Route
            path='/articles/:article'
            element={<RouteWithLayout layout={ArticleLayout} component={CommonArticle} />}
          />
          <Route
            path='/terms-of-use'
            element={
              <RouteWithLayout
                layout={StaticPage}
                title={t('footer.termsOfUse')}
                component={TermsOfUseContent}
              />
            }
          />
          <Route
            path='/faq'
            element={<RouteWithLayout layout={StaticPage} component={FAQContent} title={t('pages.faq')} />}
          />
          <Route
            path='/fleet'
            element={
              <RouteWithLayout
                layout={MainLayout}
                isTransparent={false}
                withoutBackground={true}
                component={Fleet}
              />
            }
          />
          <Route path='/search'>
            <Route
              path=':step'
              element={<RouteWithLayout layout={MainLayout} isTransparent={false} component={Order} />}
            />
          </Route>
          {/* <Route
            path='/rental-conditions'
            element={<RouteWithLayout layout={MainLayout} component={RentalConditions} />}
          />
          <Route
            path='/rental-conditions/:country'
            element={
              <RouteWithLayout layout={MainLayout} withoutBackground={true} component={SupplierChoice} />
            } 
          />*/}
          <Route
            path='/rental-conditions/:country/:supplier'
            element={
              <RouteWithLayout
                layout={MainLayout}
                withoutBackground={true}
                component={SupplierRentalConditions}
              />
            }
          />
          <Route
            path='/reservation'
            element={
              <RouteWithLayout
                layout={MainLayout}
                withoutBackground={true}
                isTransparent={false}
                component={Reservations}
              />
            }
          />
          <Route
            path='/simcards'
            element={<RouteWithLayout layout={TransparentHeader} component={SimCardsLandingPage} />}
          />

          <Route
            path='/rental-apartments'
            element={<RouteWithLayout layout={SecondaryLayout} component={RentalApartmentsLandingPage} />}
          />
          <Route
            path='/rent-apartments'
            element={<RouteWithLayout layout={SecondaryLayout} component={FormRentalLandingPage} />}
          />
          <Route path='/contact' element={<RouteWithLayout layout={SecondaryLayout} component={Contact} />} />
          <Route path='/about' element={<RouteWithLayout layout={SecondaryLayout} component={AboutUs} />} />
          <Route path='/about-us' element={<Navigate to='/about' />} />
          <Route path='/success' element={<RouteWithLayout component={SuccessPage} />} />
          <Route
            path='/all-countries'
            element={<RouteWithLayout layout={SecondaryLayout} component={AllCountries} />}
          />

          <Route
            path='/404'
            element={<RouteWithLayout layout={SecondaryLayout} component={PageNotFound} />}
          />
          <Route path='*' element={<RouteWithLayout layout={SecondaryLayout} component={PageNotFound} />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default RouterRoutes;
