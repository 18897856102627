import React from 'react';

import { Status } from '@chatscope/chat-ui-kit-react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import MuteIcon from '../images/icons/Mute_Icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeOff } from '@fortawesome/free-solid-svg-icons';
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';
import { StyledTooltip } from '../../../../../../ReusableComponents/StyledComponents';

const styles = {
  tooltip: {
    fontSize: '14px',
    padding: '8px',
  },
  icon: {
    fontSize: '17px',
  },
};
const HeaderComponent = ({
  handleClose,
  connected,
  t,
  setIsMuted,
  isMuted,
}) => {
  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography
            style={{
              fontFamily: 'Montserrat',
              fontWeight: 600,
              fontSize: 20,
            }}>
            We4rent {t('chat.chat')}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container>
            {!isMuted ? (
              <StyledTooltip
                title={t('chat.mute')}
                fontSize={styles.tooltip.fontSize}
                padding={styles.tooltip.padding}>
                <IconButton
                  style={{ width: 48, height: 48 }}
                  onClick={() => setIsMuted(true)}>
                  <FontAwesomeIcon style={styles.icon} icon={faVolumeUp} />
                </IconButton>
              </StyledTooltip>
            ) : (
              <StyledTooltip
                title={t('chat.unmute')}
                fontSize={styles.tooltip.fontSize}
                padding={styles.tooltip.padding}>
                <IconButton
                  style={{ width: 48, height: 48 }}
                  onClick={() => setIsMuted(false)}>
                  <FontAwesomeIcon style={styles.icon} icon={faVolumeOff} />
                </IconButton>
              </StyledTooltip>
            )}
            <StyledTooltip
              title={t('chat.close')}
              fontSize={styles.tooltip.fontSize}
              padding={styles.tooltip.padding}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </StyledTooltip>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          style={{
            fontFamily: 'Montserrat',
            fontSize: 12,
            opacity: 0.6,
            marginRight: 4,
          }}>
          {connected
            ? t('chat.status.available')
            : t('chat.status.unavailable')}
        </Typography>
        <Status status={connected ? 'available' : 'unavailable'} size="sm" />
      </div>
    </div>
  );
};

export default HeaderComponent;
