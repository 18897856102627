import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { displayPriceWithCurrency } from '../../../../../../utils';

const CountryCheckBox = ({
  country: { name, price, isChecked },
  isPayOnArrival,
  handleChange,
  currencyCode,
  apiType
}) => {
  const {
    t,
    i18n: {
      languages: [lng]
    }
  } = useTranslation();

  return (
    <FormControlLabel
      label={apiType !== 3 ? name[lng] + ' ' + displayPriceWithCurrency('', price, currencyCode) : name[lng]}
      control={<Checkbox checked={isChecked} onChange={e => handleChange(name, e.target.checked)} />}
    />
  );
};

export default function CrossBorderExtra({
  isPayOnArrival,
  checked,
  currencyCode,
  CRBcountries,
  handleSelected,
  apiType
}) {
  //   const [checked, setChecked] = React.useState(true);
  const [countries, setCountries] = useState(CRBcountries);
  let defaultPrice = countries[0].price;
  countries.forEach(country => {
    if (country.price > defaultPrice) {
      defaultPrice = country.price;
    }
  });

  React.useEffect(() => {
    handleSelected(
      countries.reduce((acc, curr) => {
        if (curr.isChecked) {
          return (acc += curr.price);
        } else return acc;
      }, 0)
    );
  }, [countries]);
  // React.useEffect(() => {
  //   console.log(countries);
  // }, [countries]);

  const handleCrossBorderSelected = (country, value) => {
    // console.log('country', country);
    const price = countries.find(item => item.name.en === country.en).price;
    if (price > defaultPrice) {
      defaultPrice = price;
    }
    const temp = [...countries];
    const idx = temp.findIndex(item => item.name === country);
    temp[idx].isChecked = value;
    if (apiType === 1) {
      if (value) {
        temp.forEach(item => {
          if (item.name !== country && !item.isChecked) {
            item.price = parseInt(defaultPrice / 2);
          }
        });
      }

      if (!value) {
        const ifChosen = temp.filter(item => item.isChecked);
        if (ifChosen.length === 0) {
          temp.forEach(item => (item.price = defaultPrice));
        }
        if (ifChosen.length === 1) {
          temp.forEach(function (item) {
            if (!item.isChecked) {
              item.price = parseInt(defaultPrice / 2);
            } else {
              item.price = defaultPrice;
            }
          });
        }
      }
    }
    setCountries(temp);
  };

  return (
    <div>
      <Collapse in={checked}>
        <Paper elevation={4}>
          <Grid container style={{ padding: 16 }}>
            {countries.map((country, idx) => {
              return (
                <Grid item xs={4} key={idx}>
                  <CountryCheckBox
                    country={country}
                    handleChange={handleCrossBorderSelected}
                    currencyCode={currencyCode}
                    apiType={apiType}
                    isPayOnArrival={isPayOnArrival}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Paper>
      </Collapse>
    </div>
  );
}
