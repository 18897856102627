import React from 'react';
import { StyledTooltip } from '../../LeftSection/Components/Title/Title.styled';
import Description from './components/Description';
import ItemIcon from './components/ItemIcon';
import { ItemTitle, LI, List } from './styles/AdditionalInformation.styled';
import { useTheme } from '@material-ui/core';

const AdditionalInformation = ({ additionalInformation, currencyCode }) => {
  const deposit = additionalInformation && additionalInformation.deposit;
  const fuelPolicy = additionalInformation && additionalInformation.fuelPolicy;
  const policies = additionalInformation && additionalInformation.policies;
  const { direction } = useTheme();

  const price = deposit
    ? currencyCode !== undefined
      ? new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: currencyCode
        }).format(deposit.price)
      : 0
    : 0;

  return (
    <>
      {deposit || fuelPolicy || policies ? (
        <List>
          {deposit && deposit.price && (
            <LI>
              <ItemIcon icon={'deposit'} />
              <StyledTooltip
                $direction={direction}
                $color='#000000DE'
                title={<Description description={deposit.description} />}
                arrow
              >
                <ItemTitle
                  style={{
                    fontSize: 13,
                    margin: `0 ${direction === 'ltr' ? 0 : '4px'} 0 ${direction === 'ltr' ? '4px' : 0}`
                  }}
                  underline={deposit.description !== '' || deposit.description !== undefined ? 1 : 0}
                >
                  {price} {deposit.name ? deposit.name.toLowerCase() : ''}
                </ItemTitle>
              </StyledTooltip>
            </LI>
          )}
          {fuelPolicy && (
            <LI>
              <ItemIcon icon={'fuelPolicy'} />
              <StyledTooltip
                $color='#000000DE'
                title={<Description description={fuelPolicy.description} />}
                arrow
              >
                <ItemTitle
                  style={{ marginLeft: 4 }}
                  variant='body2'
                  underline={fuelPolicy.description !== '' || fuelPolicy.description !== undefined ? 1 : 0}
                >
                  {fuelPolicy.name ? fuelPolicy.name : ''}
                </ItemTitle>
              </StyledTooltip>
            </LI>
          )}
          {policies &&
            policies.length > 0 &&
            policies.map((item, idx) => {
              return (
                <LI key={idx}>
                  <ItemIcon icon={item.policyType} />
                  <StyledTooltip
                    $color='#000000DE'
                    title={<Description description={item.description} />}
                    arrow
                  >
                    <ItemTitle
                      style={{ marginLeft: 4 }}
                      variant='body2'
                      underline={item.description !== '' || item.description !== undefined ? 1 : 0}
                    >
                      {item.name ? item.name : ''}
                    </ItemTitle>
                  </StyledTooltip>
                </LI>
              );
            })}
        </List>
      ) : (
        ''
      )}
    </>
  );
};

export default AdditionalInformation;
