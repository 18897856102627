import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { StyledCookieConsent } from '../../styles/App.styled';
import ButtonComponent from './components/ButtonComponent';

const CookieConsentBanner = () => {
  const cookieConsentRef = React.useRef();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const CustomButton = () => <ButtonComponent cookieConsentRef={cookieConsentRef} />;
  return (
    <StyledCookieConsent
      ref={cookieConsentRef}
      buttonStyle={{ display: 'none' }}
      style={{ alignItems: 'center', zIndex: 1060, padding: isMobile ? 8 : 0 }}
      // debug={true}
      ButtonComponent={CustomButton}
      expires={365}
    >
      <Typography style={{ color: '#fff' }}>
        {t('cookieConsent.bannerText')}{' '}
        <Link to='/privacy-policy' style={{ color: '#fff' }}>
          {t('cookieConsent.privacyPolicy')}
        </Link>
      </Typography>
    </StyledCookieConsent>
  );
};

export default CookieConsentBanner;
