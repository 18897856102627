import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';

export const SocialBar = styled(Grid)`
  /* border: 1px solid; */
  max-width: 1280px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 900px) {
    justify-content: center;
  }
`;

export const SocialIcon = styled(FontAwesomeIcon)`
  color: ${({ hovered }) => (hovered ? '#14c2a9' : '#fafafa')};
  margin-right: 0.5rem;
  margin: ${({ $dir }) =>
    `0 ${$dir === 'ltr' ? '0.5rem' : 0} 0 ${$dir === 'rtl' ? '0.5rem' : 0}`};
  opacity: 0.7;
  &:hover {
    color: #14c2a9;
  }
`;
export const A = styled.a`
  text-decoration: none;
  color: #fafafa;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: #14c2a9;
    bottom: -4px;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }
  &:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }
  &:hover {
    color: #14c2a9;
  }
`;
export const TradeMark = styled(Grid)`
  text-align: right;
  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;
