import Popover from '@material-ui/core/Popover';
import styled from 'styled-components';
import { DateRange } from 'react-date-range';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { GreenBorderTextField } from '../../../../../../ReusableComponents/StyledComponents';

export const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    width: ${({ $isMobile }) => ($isMobile ? '100%' : 'auto')};
  }
`;

export const StyledDateRange = styled(DateRange)`
  width: 100%;
  * {
    font-size: 0.875rem;
  }
  .rdrMonth {
    width: ${({ $isMobile }) => ($isMobile ? 'auto' : '27.667em')};
  }
  .rdrMonthName {
    color: black;
  }
  .rdrDayNumber,
  .rdrDayToday {
    font-weight: 400;
  }
  /* .rdrDayToday {
    font-size: 0.875rem;
  } */
  .rdrMonthAndYearWrapper {
    display: ${({ set }) => (set ? 'none' : 'flex')};
  }
  .rdrMonths {
    &.rdrMonthsHorizontal {
      display: ${({ set }) => (set ? 'none' : 'flex')};
    }
  }
`;

export const StyledDateTextField = styled(GreenBorderTextField)`
  .MuiInputBase-root {
    border-radius: 2px 0px 0px 2px;
  }
`;
export const StyledTimePicker = styled(Autocomplete)`
  .MuiInputBase-root {
    border-radius: 0px 2px 2px 0px;
  }
  .MuiOutlinedInput-input {
    font-weight: 500;
  }
`;
