import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import {
  LocationsContext,
  SearchDetailsContext,
} from '../../../SharedState/SearchState';
import { useTranslation } from 'react-i18next';
import { getNumberOfDays, addDays } from '../../../../utils';
import { useFormik } from 'formik';
import { dialogText, getURL, validate } from './Components/utilts';
import MobilePicker from './MobilePicker/MobilePicker';
import DesktopPicker from './DesktopPicker/DesktopPicker';
import SaturdayDriveDialog from './Components/Dialogs/SaturdayDriveDialog';

const CarPicker = ({
  isMain,
  handleStep,
  sippCode,
  isLoading,
  orderLoading,
  setOrderLoading,
}) => {
  const [searchDetails, setSearchDetails] = useContext(SearchDetailsContext);
  const [locations, setLocations] = useContext(LocationsContext);
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: searchDetails,
    onSubmit: (values) => {
      sessionStorage.setItem('searchDetails', JSON.stringify(formik.values));
      setSearchDetails(values);
      if (!formik.values.country.isLanding) {
        setTimeout(() => {
          const url = getURL(formik.values, sippCode, languages[0]);
          navigate(url);
        }, 100);
      } else {
        setOrderLoading(true);
      }
    },
    validate: (values) => validate(values, t),
  });
  const [open, setOpen] = useState({ value: false, reson: '' });
  const [saturdayDriveDialogOpen, setSaturdayDriveDialogOpen] = useState(false);
  const [pickUpVisited, setPickUpVisited] = useState(false);

  const handleClickOpen = (reason) => {
    setOpen({ value: true, reason });
  };

  // useEffect(() => {
  //   console.log('formik', formik.values);
  // }, [formik.values]);

  const handleClose = () => {
    setOpen({ value: false, reason: '' });
  };

  const {
    t,
    i18n: { languages },
  } = useTranslation();
  const sessionSearchData = JSON.parse(sessionStorage.getItem('searchDetails'));
  if (sessionSearchData) {
    sessionSearchData.pickUpDate = new Date(sessionSearchData.pickUpDate);
    sessionSearchData.dropOffDate = new Date(sessionSearchData.dropOffDate);
  }
  const [isDifferentLocation, setIsDifferentLocation] = useState(false);

  const handleSaturdayDrivingChecked = (value) => {
    if (!value) {
      formik.setFieldValue('drivingOnSaturday', true);
    } else {
      setSaturdayDriveDialogOpen(true);
    }
  };

  const handleSaturdayDrivingCloseDialog = (value) => {
    if (value === 'agree') {
      formik.setFieldValue('drivingOnSaturday', false);
    }
    setSaturdayDriveDialogOpen(false);
  };

  const handleTimeSelected = (time, isPickUpTime) => {
    // const type = isPickUpTime ? 'pickUpTime' : 'dropOffTime';
    const numberOfDays = getNumberOfDays(
      formik.values.pickUpDate,
      formik.values.dropOffDate,
      isPickUpTime ? time : formik.values.pickUpTime,
      !isPickUpTime ? time : formik.values.dropOffTime
    );

    formik.setFieldValue('dropOffTime', time);
    if (isPickUpTime) formik.setFieldValue('pickUpTime', time);
    formik.setFieldValue('numberOfDays', numberOfDays);
  };

  const handleDifferentLocation = (event) => {
    const isChecked = event.target.checked;
    if (!isChecked)
      formik.setFieldValue('dropOffLocation', formik.values.pickUpLocation);
    setIsDifferentLocation(event.target.checked);
  };

  const handleSearch = () => {
    if (formik.errors.branchClosed) {
      setOpen({ value: true, reason: 'Branch is closed' });
    } else {
      formik.handleSubmit();
    }
  };

  const handleDateSelected = (date, isPickUpdate) => {
    const typeOfDate = isPickUpdate ? 'pickUpDate' : 'dropOffDate';
    setPickUpVisited(true);
    const numberOfDays = getNumberOfDays(
      isPickUpdate ? date : formik.values.pickUpDate,
      !isPickUpdate ? date : formik.values.dropOffDate,
      formik.values.pickUpTime,
      formik.values.dropOffTime
    );
    if (typeOfDate === 'pickUpDate') {
      const dropOffDate = addDays(date, 3);
      formik.setFieldValue('pickUpDate', new Date(date));
      formik.setFieldValue('dropOffDate', new Date(dropOffDate));
    } else {
      formik.setFieldValue(typeOfDate, date);
      formik.setFieldValue('numberOfDays', numberOfDays);
    }
    // getClosed(
    //   date,
    //   typeOfDate === 'pickUpDate' ? formik.values.pickUpLocation : formik.values.dropOffLocation
    // );
  };

  const theme = useTheme();
  const sizeBetweenLGXL = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const ismobile = useMediaQuery(theme.breakpoints.down('md'));

  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const size = sizeBetweenLGXL ? 'small' : 'medium';
  const locationProps = {
    setFieldValue: formik.setFieldValue,
    onChange: formik.handleChange,
    country: formik.values.country.code,
    locations,
    setLocations,
  };

  // React.useEffect(() => {
  //   console.log('formik values', formik.values);
  //   console.log('errors', formik.errors);
  // }, [formik.values, formik.errors]);

  const datePickerRef = React.useRef(null);
  const datePickerProps = {
    handleBlur: formik.handleBlur,
    setOpen,
    handleClickOpen,
    // timeError: validatingErrors.timeError,
    handleTimeSelected,
    handleDateSelected,
    lng: languages[0],
  };
  useEffect(() => {
    if (datePickerRef) {
      pickUpVisited && datePickerRef.current.input.click();
    }
  }, [datePickerRef, pickUpVisited]);

  useEffect(() => {
    const date = new Date();
    const defaultTime = `${('0' + ((date.getHours() + 4) % 24)).slice(-2)}:00`;

    if (
      formik.values.country.code === 'il' ||
      formik.values.pickUpLocation.country.code === 'il'
    ) {
      const pickUpLocationOpeningHours =
        formik.values.pickUpLocation.OpeningHours;
      const dropOffLocationOpeningHours =
        formik.values.dropOffLocation.OpeningHours;
      const [pickUpOpen, pickUpClose] =
        pickUpLocationOpeningHours[formik.values.pickUpDate.getDay()].split(
          '-'
        );
      const [dropOffOpen, dropOffClose] =
        dropOffLocationOpeningHours[formik.values.dropOffDate.getDay()].split(
          '-'
        );

      if (pickUpOpen === '' && formik.values.pickUpTime !== '-') {
        formik.setFieldValue('pickUpTime', '-');
      }
      if (dropOffOpen === '' && formik.values.dropOffTime !== '-') {
        formik.setFieldValue('dropOffTime', '-');
      }

      if (formik.values.pickUpTime === '-' && pickUpOpen !== '') {
        formik.setFieldValue('pickUpTime', defaultTime);
      }
      if (formik.values.dropOffTime === '-' && dropOffOpen !== '') {
        formik.setFieldValue('dropOffTime', defaultTime);
      }
    } else {
      if (formik.values.pickUpTime === '-') {
        formik.setFieldValue('pickUpTime', defaultTime);
      }
      if (formik.values.dropOffTime === '-') {
        formik.setFieldValue('dropOffTime', defaultTime);
      }
    }
  }, [formik]);

  const handleLandingCarSelected = (car) => {
    formik.setFieldValue('landing.selectedCar', car);
  };
  const getDatePickerProps = (isPickUp) => {
    const time = isPickUp ? 'pickUpTime' : 'dropOffTime';
    const date = isPickUp ? 'pickUpDate' : 'dropOffDate';
    const location = isPickUp ? 'pickUpLocation' : 'dropOffLocation';
    return {
      ...datePickerProps,
      ismobile,
      datePickerLabel: t(
        `picker.carpicker.${isPickUp ? 'pickUpDate' : 'dropOffDate'}`
      ),
      country: formik.values.country.code,
      time: formik.values[time],
      date: formik.values[date],
      pickUpDate: formik.values.pickUpDate,
      dropOffDate: formik.values.dropOffDate,
      location: formik.values[location],
      timeError: formik.touched[time] && Boolean(formik.errors[time]),
      timeErrorValue: formik.errors[time],
      isPickupDate: isPickUp,
      setFieldValue: formik.setFieldValue,
      pickUpTime: formik.values.pickUpTime,
    };
  };
  const pickerProps = {
    handleSearch,
    isDifferentLocation,
    formik,
    locationProps,
    getDatePickerProps,
    handleDifferentLocation,
    saturdayDriveDialogOpen,
    handleClose,
    open,
    handleSaturdayDrivingChecked,
    size,
    handleLandingCarSelected,
    orderLoading,
    isLoading,
    matches,
    sizeBetweenLGXL,
    handleSaturdayDrivingCloseDialog,
  };
  const {
    i18n: {
      languages: [lng],
    },
  } = useTranslation();
  return (
    <>
      {ismobile || !isMain ? (
        <MobilePicker {...pickerProps} />
      ) : (
        <DesktopPicker {...pickerProps} />
      )}
      <SaturdayDriveDialog
        isOpen={saturdayDriveDialogOpen}
        handleClose={handleSaturdayDrivingCloseDialog}
      />
      <Dialog open={open.value} onClose={handleClose}>
        <DialogTitle>{t('branchIsClosedTitle')}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>{t(`${open.reason}`)}</DialogContentText> */}
          <DialogContentText>{dialogText(lng)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CarPicker;
