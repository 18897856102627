import { Dialog, DialogTitle } from "@material-ui/core";
import styled from "styled-components";

export const StyledTitle = styled(DialogTitle)`
  .MuiTypography-root {
    text-align: center;
  }
`;
export const StyledDialog = styled(Dialog)`
  .MuiDialog-paperScrollPaper {
    height: 100%-64px;
  }
`;
