import React, { useState, useEffect, lazy } from 'react';
import {
  Button,
  Grid,
  useTheme,
  useMediaQuery,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Hidden,
} from '@material-ui/core';
import styled from 'styled-components';
import { NavigateBefore } from '@material-ui/icons/';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SummaryCard from '../../OrderSummary/SummaryCard';
import Conditions from '../../Insurances/Conditions';
import { useTranslation } from 'react-i18next';
import { getIcon } from '../../images/getIcon';
import Extra from './Components/Extra';
import Insurance from './Components/Insurance';
import { useNavigate } from 'react-router-dom';
import AdditionalOptions from './Components/AdditionalOptions/AdditionalOptions';
import OrderSummarySection from '../../LeftPane/Components/PaymentDetails/Components/OrderSummarySection/OrderSummarySection';
const InsuranceOptions = lazy(() =>
  import('./Components/InsuranceOptions/InsuranceOptions')
);

const Wrapper = styled(Grid)`
  height: ${(props) => (props.ismobile ? '100%' : '90vh')};
`;

const AddInsurance = ({
  handleNext,
  handleBack,
  currencyCode,

  extrasPrices,
  setExtrasPrices,

  searchDetails,

  requestedCar,
  setRequestedCar,

  userOrder,
  setUserOrder,
  additionalOptions,
  setAdditionalOptions,
  setTotalPrice,
  totalPrice,
}) => {
  const {
    t,
    i18n: { languages },
  } = useTranslation();
  const {
    prices: { protection_coverage },
  } = requestedCar;

  const handleAdditionalInsuranceAdded = (insuranceToAddCode) => {
    const { insurances } = userOrder;
    const tempInsurances = insurances;
    if (insuranceToAddCode === 'standard') {
      tempInsurances.forEach((ins) => {
        ins.isChecked = false;
      });
      setRequestedCar({
        ...requestedCar,
        sipp_code: requestedCar.sipp_code.slice(0, 4),
        paymentInformation: {
          ...requestedCar.paymentInformation,
          amount: requestedCar.prices.priceBeforeIncDisp,
        },
      });
      setUserOrder({
        ...userOrder,
        insurances: tempInsurances,
        totalNetAmount: requestedCar.prices.priceBeforeInc,
      });
      setTotalPrice(Number(requestedCar.prices.priceBeforeIncDisp.toFixed(2)));
    } else {
      const insToAddIndex = insurances.findIndex(
        (ins) => ins.code === insuranceToAddCode
      );
      if (insToAddIndex > -1) {
        insurances[insToAddIndex].isChecked = true;
        insurances.forEach((ins) => {
          if (ins.code !== insuranceToAddCode) {
            ins.isChecked = false;
          }
        });
        setUserOrder({
          ...userOrder,
          insurances,
          totalNetAmount:
            requestedCar.prices.priceBeforeInc +
            protection_coverage[insToAddIndex].price,
        });
        const newPrice =
          requestedCar.prices.priceBeforeIncDisp +
          protection_coverage[insToAddIndex].price;
        const sipp_code =
          requestedCar.sipp_code.slice(0, 4) + insuranceToAddCode;
        setRequestedCar({
          ...requestedCar,
          sipp_code,
          paymentInformation: {
            ...requestedCar.paymentInformation,
            amount: newPrice,
          },
        });
        setTotalPrice(Number(newPrice.toFixed(2)));
      } else {
        console.error('Error while adding insurance', insuranceToAddCode);
      }
    }
  };

  // const handleAdditionalInsuranceRemoved = insuranceToRemove => {
  //   const { insurances } = userOrder;
  //   const insToRemoveIndex = insurances.findIndex(ins => ins.code === insuranceToRemove);
  //   insurances[insToRemoveIndex].isChecked = false;
  //   setUserOrder({ ...userOrder, insurances });
  //   setRequestedCar({
  //     ...requestedCar,
  //     sipp_code: requestedCar.sipp_code.replace('ZQ', ''),
  //     paymentInformation: {
  //       ...requestedCar.paymentInformation,
  //       amount: insurances[insToRemoveIndex].priceWithoutInsurance
  //     }
  //   });
  //   setTotalPrice(insurances[insToRemoveIndex].priceWithoutInsurance);
  //   handleNext();
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  if (Object.keys(requestedCar).length === 0 || !requestedCar.prices) {
    navigate('/');
  }
  const { apiType } = requestedCar;

  const handleUserCommentInput = (code, value) => {
    const id = extrasPrices.findIndex((item) => item.code === code);
    const tempArray = [...extrasPrices];
    tempArray[id].userComment = value;
    setExtrasPrices(tempArray);
  };

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const { numberOfDays } = searchDetails;

  const [open, setOpen] = useState({
    value: false,
    typeOfInsurance: '',
    description: '',
  });

  const handleClickOpen = (typeOfInsurance, description, htmlDescription) => {
    setOpen({
      value: true,
      typeOfInsurance: { name: typeOfInsurance, description, htmlDescription },
    });
  };
  const handleClose = () => {
    setOpen({ value: false, typeOfInsurance: '' });
  };
  // const handleAllChanges = () => {
  //   setExtrasPrices(extrasPrices);
  // };

  const handleAdditionalInsurance = (code, isChecked) => {
    const insurances = [...userOrder.insurances];
    // only for toprentacar
    if (code === 'FC') {
      const TWidx = insurances.findIndex(
        (insurance) => insurance.code === 'TW'
      );
      const TW = insurances[TWidx];
      insurances[TWidx] = {
        ...TW,
        isChecked: false,
        indeterminate: {
          status: isChecked,
          description: TW.indeterminate.description,
        },
      };
    }
    const idx = insurances.findIndex((insurance) => insurance.code === code);
    insurances[idx] = { ...insurances[idx], isChecked };
    setUserOrder({ ...userOrder, insurances });
  };

  const handleCrossBorderSelected = (price) => {
    const idx = extrasPrices.findIndex((extra) => extra.code === 'CRB');
    const extras = [...extrasPrices];
    extras[idx] = { ...extras[idx], price };
    setExtrasPrices(extras);
  };

  const handleChange = (name) => (event) => {
    const checkValue = event.target.checked;
    const idx = extrasPrices.findIndex((item) => item.code === name);
    const count = checkValue ? 1 : 0;
    const extras = [...extrasPrices];
    extras[idx] = { ...extras[idx], count, isChecked: count > 0 };
    setExtrasPrices([...extras]);
  };

  /**
   * Select number of specific extra
   * @param {String} name
   */
  const handleSelect = (name) => (event) => {
    const idx = extrasPrices.findIndex((extra) => extra.code === name);
    const count = event.target.value;
    const extras = [...extrasPrices];
    extras[idx] = { ...extras[idx], count, isChecked: count > 0 };
    setExtrasPrices([...extras]);
  };

  return (
    <Wrapper
      container
      direction="column"
      justifyContent="space-between"
      ismobile={isMobile.toString()}>
      <Grid item>
        <SummaryCard
          numberOfDays={numberOfDays}
          currencyCode={currencyCode}
          searchDetails={searchDetails}
          requestedCar={requestedCar}
          extrasPrices={extrasPrices}
        />
        {userOrder.insurances.length > 0 && (
          <>
            {apiType !== 4 && (
              <>
                {' '}
                <Typography
                  variant="h6"
                  id="tableTitle"
                  style={{ marginBottom: '16px', marginLeft: 16 }}>
                  {t('protection.information')}
                </Typography>
                <TableContainer
                  component={Paper}
                  elevation={0}
                  variant="outlined">
                  <Table size="small" dense>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('protection.protectionType')}</TableCell>
                        <TableCell align="center">
                          {t('protection.pricePerDay')}
                        </TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {userOrder.insurances.map((insurance) => {
                        return (
                          <Insurance
                            handleAdditionalInsurance={
                              handleAdditionalInsurance
                            }
                            key={insurance.code}
                            insurance={insurance}
                            currencyCode={currencyCode}
                            handleClickOpen={handleClickOpen}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        )}
        {open.value && <Conditions handleClose={handleClose} open={open} />}
        {additionalOptions.length > 0 && (
          <AdditionalOptions
            additionalOptions={additionalOptions}
            setAdditionalOptions={setAdditionalOptions}
          />
        )}
        <Typography
          variant="h6"
          id="tableTitle"
          style={{ marginBottom: '16px', marginTop: '16px', marginLeft: 16 }}>
          {t('protection.extras')}
        </Typography>
        <Paper elevation={0} variant="outlined" style={{ marginBottom: '8px' }}>
          <Grid container direction="column">
            {extrasPrices.map((extra) => {
              const dontShow = extra.hasToBeDisplayed === false;
              return (
                <div key={extra.code}>
                  {!dontShow === true && (
                    <>
                      <Extra
                        currencyCode={currencyCode}
                        handleSelect={handleSelect}
                        extra={extra}
                        t={t}
                        name={extra.name}
                        description={extra.description}
                        isMobile={isMobile}
                        icon={getIcon(extra.code)}
                        handleChange={handleChange}
                        handleClickOpen={handleClickOpen}
                        handleCrossBorderSelected={handleCrossBorderSelected}
                        apiType={apiType}
                        handleUserCommentInput={handleUserCommentInput}
                      />
                      <Divider />
                    </>
                  )}
                </div>
              );
            })}
          </Grid>
        </Paper>
        {apiType === 4 && (
          <Typography variant="body2" style={{ paddingLeft: 12 }}>
            {t('disclamers.exactPrices')}
          </Typography>
        )}
        <Hidden mdUp>
          <OrderSummarySection
            requestedCar={requestedCar}
            userOrder={userOrder}
            totalPrice={totalPrice}
            additionalOptions={additionalOptions}
            extrasPrices={extrasPrices}
          />
          {/* {apiType === 4 ? (
            <PaymentDetails
              totalPrice={totalPrice}
              requestedCar={requestedCar}
              extras={extrasPrices}
              insurances={userOrder.insurances}
              currency={currencyCode}
            />
          ) : (
            <UserOrderSummary
              requestedCar={requestedCar}
              amountToPay={
                apiType !== 3 &&
                requestedCar.paymentInformation &&
                requestedCar.paymentInformation.isPaymentRequired
                  ? requestedCar.paymentInformation.amount
                  : 0
              }
              country={requestedCar.country}
              saturdayInsurance={requestedCar.prices.protection_coverage.saturdayInsurance}
              userOrder={userOrder}
              currency={currencyCode}
              extrasPrices={extrasPrices}
              additionalOptions={additionalOptions}
              totalPrice={totalPrice}
            />
          )} */}
        </Hidden>
      </Grid>
      <Grid item>
        {apiType === 4 && protection_coverage.length > 0 && (
          <InsuranceOptions
            requestedCar={requestedCar}
            handleAdditionalInsuranceAdded={handleAdditionalInsuranceAdded}
          />
        )}
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            {' '}
            <Button
              style={{ margin: '16px 0px', fontSize: apiType === 4 ? 12 : 14 }}
              size="small"
              onClick={handleBack}>
              {languages[0] === 'he' ? (
                <NavigateNextIcon />
              ) : (
                <NavigateBefore />
              )}
              {isMobile ? t('common.back') : t('common.backToCarChoosing')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              disableElevation={true}
              style={{
                margin: '0 8px 0 0',
                color: 'white',
                fontSize: apiType === 4 ? 12 : 14,
              }}
              onClick={() => {
                handleNext();
              }}>
              <div>
                {t('common.goToSummary')}
                <br />
              </div>

              {languages[0] === 'he' ? (
                <NavigateBefore />
              ) : (
                <NavigateNextIcon />
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default AddInsurance;
