import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PriceRangeWrapper, StyledSlider, Title } from '../styles/PriceRange.styled';

const getPrice = (number, currency) => {
  if (!currency) {
    return number;
  }
  return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(number);
};

const PriceRangeFilter = ({
  handlePriceRangeChange,
  value,
  currency,
  cars,
  handleRangeChange,
  valuetext,
  minMaxPrice
}) => {
  const { code } = currency;
  const { t } = useTranslation();
  return (
    <PriceRangeWrapper>
      <Title>
        <Typography style={{ fontWeight: 600, marginTop: 20 }}>{t('filters.priceRange')}</Typography>
        <Typography component='span' variant='body2'>
          {getPrice(minMaxPrice[0], code)}
        </Typography>
        -
        <Typography component='span' variant='body2'>
          {getPrice(minMaxPrice[1], code)}
        </Typography>
      </Title>
      <StyledSlider
        min={minMaxPrice[0]}
        max={minMaxPrice[1]}
        value={value}
        // marks={true}
        onChange={handlePriceRangeChange}
        valueLabelDisplay='auto'
        aria-labelledby='range-slider'
        getAriaValueText={valuetext}
      />
    </PriceRangeWrapper>
  );
};

export default PriceRangeFilter;
