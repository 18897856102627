import React from 'react';
import { changeURLByLanguage } from './utils';
import russianFlag from '../../../../../images/assets/flags/russia.svg';
import usaFlag from '../../../../../images/assets/flags/usa.svg';
import israelFlag from '../../../../../images/assets/flags/israel.svg';

const Flag = chosenLanguage => {
  const lg = chosenLanguage.item;
  const translateLanguage = lg === 'Русский' ? 'ru' : lg === 'English' ? 'en' : 'he';
  const flag = lg === 'Русский' ? russianFlag : lg === 'English' ? usaFlag : israelFlag;
  window.location.replace(changeURLByLanguage(translateLanguage));

  return <img src={flag} alt='flag' style={{ width: '20px' }}></img>;
};

export default Flag;
