import React from 'react';
import axios from 'axios';
import { apiUrl } from '../../../../../../../../../../utils';
import ReactHtmlParser, { domToReact } from 'html-react-parser';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
const Loading = () => {
  const arr = new Array(10).fill();
  return (
    <div>
      {arr.map((item, idx) => {
        return <Skeleton key={idx} variant='text' />;
      })}
    </div>
  );
};

const options = {
  replace: ({ attribs, children }) => {
    if (!attribs) {
      return;
    }
    if (attribs.style) {
      if (attribs.style.includes('Arial')) {
        const style = attribs.style;
        const montserrrat = style.replace('Arial', 'Montserrat');
        attribs.style = montserrrat;
        return attribs;
      }
    }
  }
};

const AddOnsDialog = ({ supplier, country, handleClose, open, lng, setAddOnsDialogOpen }) => {
  const ismobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [addOns, setAddons] = React.useState();
  React.useEffect(() => {
    axios
      .get(apiUrl(`getaddons?country=${country.code}&supplier=${supplier.code}&lng=${lng}`))
      .then(res => setAddons(res.data))
      .catch(err => {
        console.log('ERROR', err);
        setAddOnsDialogOpen(false);
      });
  }, []);
  // React.useEffect(() => {
  //   console.log('ADDONS', addOns);
  // }, [addOns]);
  return (
    <Dialog fullScreen={ismobile} maxWidth='md' open={open} onClose={handleClose} scroll='paper'>
      <DialogTitle></DialogTitle>
      {!addOns ? (
        <Loading />
      ) : (
        <DialogContent dividers={true}>{ReactHtmlParser(addOns, options)}</DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddOnsDialog;
