import axios from 'axios';
import React, { useState, createContext } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getCountryNameByCode } from '../../data';
import { apiUrl } from '../../utils';
import { getOrderId } from '../../utils/utils';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const date = new Date();
const pickUpTime = `${('0' + ((date.getHours() + 4) % 24)).slice(-2)}:00`;
const { pathname } = window.location;
const loc = pathname.split('/');
let countryCode;

if (loc.find((l) => l === 'country')) {
  const idx = loc.findIndex((l) => l === 'country');
  countryCode = loc[idx + 1];
}

let sessionSearchData = JSON.parse(sessionStorage.getItem('searchDetails'));
if (
  countryCode &&
  sessionSearchData &&
  countryCode !== sessionSearchData.country.code
) {
  sessionStorage.clear();
  sessionSearchData = null;
}

if (sessionSearchData) {
  sessionSearchData.pickUpDate = new Date(sessionSearchData.pickUpDate);
  sessionSearchData.dropOffDate = new Date(sessionSearchData.dropOffDate);
}
/**
 *
 * @param {Array} locationsList
 * @param {String} locationCode
 * @returns
 */
const getLocationByLocationCode = (locationsList, locationCode, isPickup) => {
  const location = locationsList.find(
    (loc) => loc.LocationCode === locationCode
  );
  return location;
};

const getInitialLocation = (isPickUp) => {
  return {
    name: { en: '', ru: '', he: '' },
    LocationName: {
      en: '',
      ru: '',
      he: '',
    },
    country: { code: '', name: '' },
    city: { en: '', ru: '', he: '' },
    LocationCode: urlParams.get(isPickUp ? 'pl' : 'dl') || null,
    iataCodeId:
      urlParams.get(isPickUp ? 'pickUpIataId' : 'dropOffIataId') || null,
  };
};
/**
 * Contexes
 */
export const SearchDetailsContext = createContext();
export const BackGroundImageContext = createContext();
export const LocationsContext = createContext();
export const ArticlesContext = createContext();
export const AgentContext = createContext();

const initialState = sessionSearchData
  ? sessionSearchData
  : {
      country: {
        code: urlParams.get('country') || null,
        name: urlParams.get('country')
          ? getCountryNameByCode(urlParams.get('country'))
          : null,
      },
      pickUpLocation: getInitialLocation(true),
      dropOffLocation: getInitialLocation(false),
      pickUpDate: new Date(),
      pickUpTime: urlParams.get('pickUpHour') || pickUpTime,
      dropOffDate: new Date(date.setDate(date.getDate() + 3)),
      dropOffTime: urlParams.get('dropOffHour') || pickUpTime,
      driverAge: urlParams.get('age') || '',
      drivingOnSaturday: true,
      landing: {
        isLanding: false,
        selectedCar: '',
      },
      orderId: getOrderId(),
    };
const Store = ({ children }) => {
  const [locations, setLocations] = useState({ countryCode: '', stations: [] });

  const [searchDetails, setSearchDetails] = useState(initialState);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [articles, setArticles] = useState({ posts: [], page: 1 });
  const [agent, setAgent] = useState(null);

  const {
    i18n: {
      languages: [lng],
    },
  } = useTranslation();
  const { search } = useLocation();

  useEffect(() => {
    axios
      .get(apiUrl(`articles/getpaginatedposts?lng=${lng}&page=1`))
      .then((res) => {
        const fetchedPosts = res.data.articles;
        if (fetchedPosts.length > 0) {
          setArticles({ posts: fetchedPosts, page: 1 });
        }
      })
      .catch((err) => console.log(err));
  }, [lng]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const country = query.get('country');
    const pl = query.get('pl');
    const dl = query.get('dl');
    if (
      (pl && pl !== searchDetails.pickUpLocation.LocationCode) ||
      (dl && dl !== searchDetails.dropOffLocation.LocationCode) ||
      (country && country !== searchDetails.country.code)
    ) {
      if (
        country !== locations.countryCode ||
        country !== searchDetails.country.code
      ) {
        axios.get(apiUrl(`getstations?country=${country}`)).then((response) => {
          if (response.data) {
            setLocations({
              countryCode: searchDetails.country.code,
              stations: response.data,
            });
          }
          const pickUpLocation = getLocationByLocationCode(
            response.data,
            query.get('pl'),
            true
          );
          const dropOffLocation = getLocationByLocationCode(
            response.data,
            query.get('dl'),
            false
          );
          setSearchDetails({
            ...searchDetails,
            country: {
              code: country,
              name: country === null ? null : getCountryNameByCode(country),
            },
            pickUpLocation,
            dropOffLocation,
          });
        });
      } else {
        const pickUpLocation = getLocationByLocationCode(
          locations.stations,
          query.get('pl'),
          true
        );
        const dropOffLocation = getLocationByLocationCode(
          locations.stations,
          query.get('dl'),
          false
        );

        setSearchDetails({
          ...searchDetails,
          country: {
            code: country,
            name: country === null ? null : getCountryNameByCode(country),
          },
          pickUpLocation,
          dropOffLocation,
        });
      }
    }
  }, [search]);

  return (
    <AgentContext.Provider value={[agent, setAgent]}>
      <SearchDetailsContext.Provider value={[searchDetails, setSearchDetails]}>
        <BackGroundImageContext.Provider
          value={[backgroundImage, setBackgroundImage]}>
          <LocationsContext.Provider value={[locations, setLocations]}>
            <ArticlesContext.Provider value={[articles, setArticles]}>
              {' '}
              {children}
            </ArticlesContext.Provider>
          </LocationsContext.Provider>
        </BackGroundImageContext.Provider>
      </SearchDetailsContext.Provider>
    </AgentContext.Provider>
  );
};
export default Store;
