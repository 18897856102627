import React from 'react';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import useScroll from '../../../utils/hooks/useScroll';
import { useMediaQuery, useTheme } from '@material-ui/core';

// const DISTANCE = 200;

const BackToTopButton = () => {
  const theme = useTheme();
  const ismobile = useMediaQuery(theme.breakpoints.down('md'));

  const { crossed } = useScroll(200);
  function onClick() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  if (!crossed) {
    return null;
  } else {
    return (
      <Fab
        onClick={onClick}
        style={{
          position: 'fixed',
          bottom: ismobile ? 8 : 32,
          right: ismobile ? 8 : 32,
          zIndex: 1
        }}
        color='secondary'
        aria-label='edit'
      >
        <FontAwesomeIcon icon={faArrowUp} style={{ color: 'white' }} />
      </Fab>
    );
  }
};
export default BackToTopButton;
