import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Hidden,
  useMediaQuery,
  useTheme,
  Link,
} from '@material-ui/core';
import {
  faTelegram,
  faFacebook,
  faWhatsapp,
  faVk,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import {
  A,
  SocialBar,
  SocialIcon,
  TradeMark,
} from './styles/BottomContactsBar.styled';

const BottomContactsBar = () => {
  const theme = useTheme();
  const { direction } = theme;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(false);

  const whatsappURL = `https://wa.me/972542041614?text=${t('chat.whatsapp')}`;
  return (
    <Grid container style={{ height: '2rem' }} justifyContent="space-between">
      {/* <Hidden lgDown>
        <Grid item xl={2} lg={2} />
      </Hidden> */}

      <SocialBar item xs={12} sm={6} style={{ justifyContent: 'flex-start' }}>
        <Typography
          onMouseOver={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          variant="body2"
          style={{
            margin: `0 ${direction === 'ltr' ? '1rem' : 0} 0 ${
              direction === 'rtl' ? '1rem' : 0
            }`,
            opacity: 0.7,
          }}>
          <A
            href="mailto:contact@we4rent.com"
            rel="noopener noreferrer"
            target="_blank">
            <SocialIcon
              $dir={direction}
              icon={faEnvelope}
              hovered={hovered ? 1 : 0}
            />
            contact@we4rent.com
          </A>
        </Typography>
        <a
          href="https://facebook.com/we4rent"
          rel="noopener noreferrer"
          target="_blank">
          <SocialIcon $dir={direction} icon={faFacebook} />
        </a>
        <a
          href="https://vk.com/we4rent"
          rel="noopener noreferrer"
          target="_blank">
          <SocialIcon $dir={direction} icon={faVk} />
        </a>
        <a
          href="https://t.me/Holylandcars_bot"
          rel="noopener noreferrer"
          target="_blank">
          <SocialIcon $dir={direction} icon={faTelegram} />
        </a>
        <a href={whatsappURL} rel="noopener noreferrer" target="_blank">
          <SocialIcon $dir={direction} icon={faWhatsapp} />
        </a>
      </SocialBar>
      <TradeMark
        item
        xs={12}
        sm={6}
        style={{ textAlign: isMobile ? 'left' : 'right' }}>
        <Typography variant="caption" style={{ color: '#fff', opacity: 0.7 }}>
          © 2020 www.we4rent.com
        </Typography>
        <br />
        <Typography variant="caption" style={{ color: '#fff', opacity: 0.7 }}>
          Design by{' '}
          <Link style={{ color: '#fff' }} href="https://n1creative.net">
            N1 Creative
          </Link>
        </Typography>
      </TradeMark>
      <Hidden lgDown>
        <Grid
          item
          xl={2}
          lg={2}
          // style={{ border: '1px solid' }}
        ></Grid>
      </Hidden>
    </Grid>
  );
};

export default BottomContactsBar;
