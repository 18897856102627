import { ChatContainer, MessageList } from '@chatscope/chat-ui-kit-react';
import { Paper } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid;
`;

export const StyledFloatingButton = styled(Fab)`
  margin: 0px;
  top: auto;
  left: auto;
  right: ${({ ismobile }) => (ismobile ? '10px' : '32px')};
  bottom: ${(props) =>
    props.crossed
      ? props.ismobile
        ? '70px'
        : '100px'
      : props.ismobile
      ? '10px'
      : '32px'};
  transition: 0.5s !important;
`;
export const ChatWrapper = styled(Paper)`
  border-radius: 0.7em !important;
  z-index: 100;
  min-width: 300px;
  position: fixed;
  left: auto;
  transition: 0.5s !important;
  right: ${({ ismobile }) => (ismobile ? '10px' : '32px')};
  height: 500px;
  bottom: ${(props) =>
    props.crossed
      ? props.ismobile
        ? '135px'
        : '164px'
      : props.ismobile
      ? '80px'
      : '100px'};
`;

export const StyledChatContainer = styled(ChatContainer)`
  max-width: 300px;
`;
export const StyledMessageList = styled(MessageList)`
  padding-top: 12px;
`;
