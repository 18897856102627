import {  Checkbox, FormControlLabel, Link } from '@material-ui/core';
import React  from 'react';
import DescriptionDialog from './DescriptionDialog';
import { useTranslation } from 'react-i18next';

const Option = ({ option: { name, isChecked, productId, checkBoxDescription }, handleOptionSelected }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const {
    t,
    i18n: {
      languages: [lng]
    }
  } = useTranslation();
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            value={productId}
            color='primary'
            onChange={({ target: { value, checked } }) => handleOptionSelected(value, checked)}
          />
        }
        label={name}
      />
      <div>
        {checkBoxDescription} {t('protection.forMoreInfo')}
        <Link style={{ cursor: 'pointer' }} onClick={() => setDialogOpen(true)}>
          {' '}{t('common.here')} ->
        </Link>
      </div>

      <DescriptionDialog lng={lng} open={dialogOpen} setDialogOpen={setDialogOpen} optionId={productId} />
    </>
  );
};

export default Option;
