import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';


const SaturdayDriveDialog = ({ isOpen, handleClose }) => {
  const { i18n, t } = useTranslation();
  return (
    <Dialog open={isOpen} onClose={() => handleClose('disagree')}>
      <DialogTitle>{t('picker.carpicker.saturdayDrive')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogBody(i18n.languages[0])}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose('agree')} color='primary' autoFocus>
          {t('picker.carpicker.agree')}
        </Button>

      </DialogActions>
    </Dialog>
  );
};

const dialogBody = language => {
  switch (language) {
    case 'ru':
      return `Спец тариф для тех, кто не водит автомобиль по субботам.
    Вы не водите автомобиль в субботу? Мы предлагаем специальный тариф Shabbat Off 
    Тариф предназначен для религиозных клиентов, которые не водят автомобиль по субботам. 
    Важно понимать - при согласии на данный тариф вы соглашаетесь на тариф без страховки на вождение автомобиля в субботу или в религиозный праздник (песах, еврейский новый год, суккот). В случае нарушения условий контракта вы несете полную ответственность за ущерб на арендованном автомобиле, а также за ущерб причинённый третьим лицам.
    Убедительная просьба не использовать данный тариф в целях экономии если вы не соблюдаете субботу по религиозным причинам.
    `;
    case 'en':
      return `Special Religious Rate for those people, who do not drive on Saturdays.
    Don't you drive on Saturday? We offer a special Shabbat Off rate for you.
    The rate is for religious customers who do not drive on Saturdays.
    It is very important to understand - when you click on the agree button, you agree to the special conditions that do not include insurance for driving a car on Saturday or on religious holidays (Pesach, Jewish New Year, Sukkot). In case of violation of the terms of the contract, you are fully responsible for damage caused to the rented car, as well as for damage caused to third parties.
    We kindly ask you, do not use this rate in order to save money if you do not really observe Saturday for religious reasons .
    `;
    case 'he':
      return `מחירון הנ"ל מיועד לאנשי דת שלא נוהגים ברכב בשבת או חג.
    אתה לא נוהג בשבת ? 
    אתה לא נוהג בשבת? יש לנו מחיר מיוחד בשבילך עם הנחת שבת
    חשוב מאוד להבין - בעת לחיצת כפתור אני מסכים, אתה מאשר תנאים מיוחדים של המחירון שאינם כלולים ביטוח לנהיגה ברכב בשבת או בחג דתי (פסח, ראש השנה עברי, סוכות). 
    במקרה של הפרת תנאים של החוזה, האחראיות על נזק ברכב שכור חלה באופן מלא על הלקוח, וכן לנזק שנגרם לצד ג.
    אנו מבקשים לא להשתמש במחירון זה בכדי לחסוך במידה ואינך מקיים שבת
    `;
    default:
      return '';
  }
};

export default SaturdayDriveDialog;
