import { Grid, Paper, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const StyledPaper = styled(Paper)`
  /* margin-top: 16px; */
`;
export const Title = styled(Typography)`
  padding: 0 16px 0 16px;
  height: 64px;
  display: flex;
  align-items: center;
`;

export const StyledGridRow = styled(Grid)`
  padding: 8px 16px;
  border-bottom: ${({ $withoutBorder }) =>
    $withoutBorder ? 'none' : '1px solid #c4c4c4'};
  display: flex;
  align-items: center;
`;
export const StyledGridCell = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: ${({ $justifyContent }) => $justifyContent || "end"};
  text-align: ${({ $align }) => $align};
`;
