import { Card } from '@material-ui/core';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  max-width: 800px;
  padding: 16px;
  padding-top: 30px;
  padding-left: 37px;
  padding-bottom: 16px;
  padding-right: 27px;
  margin-bottom: 16px;
`;
