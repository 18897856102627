import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const StyledPaper = styled(Paper)`
  padding: 32px;
  height: ${({ $ismobile }) => ($ismobile ? 'auto' : '300px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
