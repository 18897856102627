import { Tooltip } from '@material-ui/core';
import styled from 'styled-components';

export const AgeErrorTooltip = styled(props => {
  return <Tooltip enterTouchDelay={0} classes={{ popper: props.className }} {...props} />;
})`
    .MuiTooltip-tooltip {
      display: flex;
      background-color: white;
      border-radius: 2px;
      box-shadow: 0px 0px 24px #00000034;
      color:red;
    }
    .MuiTooltip-arrow {
      &:before {
        background-color: white;
      }
  `;
