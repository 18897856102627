import React from "react";
import Included from "./Components/Included";
import { useTranslation } from "react-i18next";
import PricePerPeriod from "../../../CardComponents/PricePerPeriod";
import TotalPricePerPeriod from "../../../CardComponents/TotalPricePerPeriod";
import { PriceContainer, PriceSection } from "./styles/RightSection.styled";
import AdditionalInformation from "./AdditionalInformation/AdditionalInformation";
import { Grid, useTheme } from "@material-ui/core";
import { getDisplayPriceWithVat } from "../../../../../../../utils/utils";

const RightSection = ({
  protections,
  basePrice,
  isLoading,
  included,
  vatLevel,
  ...rest
}) => {
  const { price, totalBasePrice, VATIncluded, period, pricePerPeriod } =
    basePrice || {};
  const { direction } = useTheme();
  const { t } = useTranslation();
  return (
    <PriceContainer container direction="column" spacing={1}>
      <PriceSection item>
        <PricePerPeriod
          direction={direction}
          basePrice={basePrice}
          isLoading={isLoading}
          isMobileCard={false}
          vatLevel={vatLevel}
          {...rest}
        />
      </PriceSection>

      <TotalPricePerPeriod
        VATIncluded={VATIncluded}
        vatLevel={vatLevel}
        direction={direction}
        t={t}
        isLoading={isLoading}
        price={price}
        totalBasePrice={getDisplayPriceWithVat(
          totalBasePrice,
          VATIncluded,
          vatLevel
        )}
        displayPrice={pricePerPeriod !== -1}
        {...rest}
      />
      <Grid item style={{ marginTop: 4 }}>
        <AdditionalInformation {...rest} />
      </Grid>
      {pricePerPeriod !== -1 && (
        <Grid item style={{paddingBottom:8}}>
          <Included
            VATIncluded={VATIncluded}
            protections={protections}
            period={period}
            isLoading={isLoading}
            included={included}
            isPC={true}
          />
        </Grid>
      )}
    </PriceContainer>
  );
};

export default RightSection;
