import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import styled from 'styled-components';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const ParagraphHeading = styled.span`
  font-weight: bold;
`;
export const Expansion = styled(Accordion)`
  &.MuiPaper-elevation1 {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }

  .MuiAccordionSummary-root {
    justify-content: flex-start;
    padding: 0px;
  }
  .MuiAccordionSummary-content {
    flex-grow: 0;
  }
  .MuiTypography-body1 {
    font-size: 0.875rem;
  }
`;
export const Conditions = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ExpandedPanel = ({ title, description }) => {
  return (
    <Expansion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography> {title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{description}</Typography>
      </AccordionDetails>
    </Expansion>
  );
};
