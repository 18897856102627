import { Button, CircularProgress, Grid } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { checkOutUrl } from '../../../../utils';
import useFetch from '../../../../utils/hooks/useFetch';

const PaymentPage = ({
  personalDetails,
  requestedCar,
  handleNext,
  handleBack,
  validateAndSendEmail,
  currencyCode,
  totalPrice
}) => {
  const {
    t,
    i18n: {
      languages: [lng]
    }
  } = useTranslation();
  const [windowURL, setWindowURL] = useState('');
  const [paymentStatus, setPaymentStatus] = useState();

  
  // console.log('PERSONAL DETAILS', personalDetails);
  const { response, error, isLoading } = useFetch({
    method: 'get',
    url: checkOutUrl(
      `getPaymentWindowUrl?&carModel=${requestedCar.vehicle_type}&amount=${totalPrice}&uuid=${
        personalDetails.uuid
      }&DocumentLanguage=${lng === 'he' ? 'he' : 'en'}&CustomerLastName=${
        personalDetails.lastName
      }&CustomerFirstName=${personalDetails.firstName}&EmailAddress=${
        personalDetails.email
      }&currency=${currencyCode}`
    )
  });

  useEffect(() => {
    if (response) {
      setWindowURL({ URL: response.URL });
    }
  }, [response, error, isLoading]);
  // const history = useNavigate();
  useEffect(() => {
    console.log('STATUS', paymentStatus);
  }, [paymentStatus]);

  // const verifyPaymentAndMakeOrder = () => {
  //   axios
  //     .get(
  //       checkOutUrl(
  //         `ipn/verify?uuid=${personalDetails.uuid ? personalDetails.uuid : sessionStorage.getItem('uuid')}`
  //       )
  //     )
  //     .then(response => {
  //       setPaymentStatus(response.data.status);
  //     })
  //     .catch(err => console.error('error with verifying the payment', err));
  // };
  useEffect(() => {
    if (paymentStatus) {
      validateAndSendEmail(
        personalDetails.title,
        personalDetails.email.toLowerCase(),
        personalDetails.firstName,
        personalDetails.lastName,
        personalDetails.phone,
        personalDetails.age,
        personalDetails.isSubscribed,
        personalDetails.flightNumber,
        personalDetails.customerComment
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatus]);
  // const frameRef = useRef();
  // useEffect(() => {
  //   console.log('REF', frameRef.current.document);
  // }, [frameRef, windowURL]);
  return (
    <Grid container direction='column' justifyContent='space-between' style={{ height: '99%' }}>
      <Grid item xs={12} style={{ height: '95%' }}>
        <form action='POST'>
          <iframe
            scrolling='no'
            title='payment'
            // ref={frameRef}
            src={windowURL.URL}
            height='100%'
            width='100%'
            style={{ minHeight: 850 }}
            frameBorder='0'
            // onLoad={() => {
            //   console.log('onload', frameRef.current.attributes);
            //   setCounter(counter + 1);
            // }}
          />
        </form>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Button
              onClick={handleBack}
              variant='contained'
              color='secondary'
              style={{ margin: '16px 0px', color: 'white' }}
            >
              {t('common.back')}
            </Button>
          </Grid>

          <Grid item style={{ position: 'relative' }}>
            {/* <Button
              disabled={loading}
              variant='contained'
              color='secondary'
              style={{ margin: '16px 0px', color: 'white' }}
              onClick={() => {
                setLoading(true);
                verifyPaymentAndMakeOrder();
              }}
            >
              {t('common.order')}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }}
              />
            )} */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentPage;
