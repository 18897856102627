import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from '../../../../../../utils';
const CarSelection = ({ size, handleLandingCarSelected, selectedCar, country, t }) => {
  const [cars, setCars] = React.useState([]);

  useEffect(() => {
    if (country.isLanding) {
      const url = apiUrl(`landing/getcars?country=${country.code}`);
      axios
        .get(url)
        .then(res => setCars(res.data))
        .catch(err => console.error(err));
    }
  }, [country]);
  return (
    <FormControl variant='outlined' fullWidth size={size}>
      <InputLabel
      //    error={error}
      >
        {t('picker.carpicker.selectacar')}
      </InputLabel>
      <Select
        label={t('picker.carpicker.selectacar')}
        value={selectedCar}
        // name='driverAge'
        onChange={event => handleLandingCarSelected(event.target.value)}
        fullWidth
        variant='outlined'
      >
        {cars.length > 0 &&
          cars.map((car, idx) => (
            <MenuItem key={idx} value={car}>
              {car.vehicle_type}
            </MenuItem>
          ))}
      </Select>
      {/* <FormHelperText style={{ color: '#f44336' }}>{error && helperText}</FormHelperText> */}
    </FormControl>
  );
};

export default CarSelection;
