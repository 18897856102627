import { Slider } from '@material-ui/core';
import styled from 'styled-components';

export const PriceRangeWrapper = styled.div`
  padding: 0 16px 4px 16px;
`;
export const Title = styled.div``;

export const StyledSlider = styled(Slider)`
  margin-top: 16px;
  margin-bottom: 16px;
`;
