import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import Subtitle from '../Subtitle';

const Title = ({ sipp_code, isLoading, carModel, isModelGuaranteed, year, t }) => {
  return (
    <Grid container justifyContent='space-between' style={{ padding: '16px' }} alignItems='center'>
      <Grid item>
        <Typography variant='h6' style={{ fontWeight: 600 }}>
          {isLoading ? (
            <Skeleton style={{ marginBottom: 4 }} animation='wave' variant='rectangular' />
          ) : (
            carModel
          )}
        </Typography>
        {isLoading ? (
          <Skeleton variant='rectangular' width={170} height={20} />
        ) : (
          <Subtitle isModelGuaranteed={isModelGuaranteed} year={year} t={t} sipp_code={sipp_code} />
        )}{' '}
      </Grid>
    </Grid>
  );
};
export default Title;
