import {
  Link,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { displayPriceWithCurrency } from '../../../../../../utils/utils';
import {
  StyledCheckBox,
  StyledFormControlLabel,
} from './styles/Insuranse.styled';

const InsurancePrice = ({ price, isPayOnArrival, t, currencyCode }) => {
  if (price === 0) {
    return (
      <span style={{ color: '#14C2A9', fontWeight: 500 }}>
        {t('common.included')}
      </span>
    );
  }
  if (price === -1) {
    if (isPayOnArrival) {
      return '';
    } else return t('common.notAvailable');
  } else return `${displayPriceWithCurrency('', price, currencyCode)}`;
};

const Insurance = ({
  insurance,
  currencyCode,
  handleClickOpen,
  handleAdditionalInsurance,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const { code, name, description, isChecked, price, indeterminate } =
    insurance;
  const getIndeterminate = () => {
    return indeterminate
      ? indeterminate.status
      : price === -1 && !insurance.isPayOnArrival;
  };

  const isIndeterminate = getIndeterminate();

  const getDisabled = () => {
    if (isIndeterminate) {
      return true;
    } else
      return (
        (price < 0 && !insurance.isPayOnArrival) ||
        price === 0 ||
        insurance.hasToBeIncluded
      );
  };
  const handleTooltipOpen = () => {
    if (indeterminate && indeterminate.status) {
      setTooltipOpen(true);
    }
  };
  return (
    <Tooltip
      placement="bottom-start"
      title={indeterminate ? indeterminate.description : ''}
      open={tooltipOpen}
      onClose={() => setTooltipOpen(false)}
      onOpen={handleTooltipOpen}>
      <TableRow>
        <TableCell component="th" scope="row">
          <StyledFormControlLabel
            control={
              <StyledCheckBox
                checkboxcolor={theme.palette.secondary.main}
                $disabledColor={theme.palette.secondary.main}
                disabled={getDisabled()}
                checked={isChecked}
                value={code}
                $indeterminate={isIndeterminate}
                indeterminate={getIndeterminate()}
                color="primary"
                onChange={(e, value) =>
                  handleAdditionalInsurance(e.target.value, value)
                }
              />
            }
            label={name}
          />
        </TableCell>
        <TableCell align="center">
          <InsurancePrice
            price={price}
            isPayOnArrival={insurance.isPayOnArrival}
            t={t}
            currencyCode={currencyCode}
          />
        </TableCell>
        <TableCell align="right">
          <Link
            underline={insurance.description ? 'hover' : 'none'}
            // component='button'
            disabled={!insurance.description}
            onClick={() => handleClickOpen(name, description)}
            style={{
              cursor: insurance.description ? 'pointer' : 'auto',
              color: !insurance.description
                ? 'rgba(0, 0, 0, 0.26)'
                : 'rgba(0, 0, 0, 0.87)',
            }}>
            {t('protection.readMore')}
          </Link>
        </TableCell>
      </TableRow>
    </Tooltip>
  );
};

export default Insurance;
