import { Tooltip } from "@material-ui/core";
import styled from "styled-components";

export const CarName = styled.div`
  text-align: ${({ direction }) => (direction === "ltr" ? "left" : "right")};
  font-weight: 900;
  font-size: 40px;
  line-height: 47px;
`;

export const Subtitle = styled.div`
  text-align: ${({ direction }) => (direction === "ltr" ? "left" : "right")};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

export const IsModelGuaranteed = styled.span`
  &:hover {
    cursor: help;
  }
`;

export const StyledTooltip = styled((props) => {
  return (
    <Tooltip
      enterTouchDelay={0}
      classes={{ popper: props.className }}
      {...props}
    />
  );
})`
  .MuiTooltip-tooltip {
    direction: ${({ $direction }) => $direction || "ltr"};
    color:${({ $color }) => ($color ? $color : "initial")};
    display: flex;
    padding:12px;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0px 0px 24px #00000034;
  }
  .MuiTooltip-arrow {
    &:before {
      background-color: white;
    }
`;
