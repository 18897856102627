import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const SessionExpiredDialog = ({ open, title, content }) => {
  const navigate = useNavigate();

  return (
    <Dialog open={open} onClose={() => {}}>
      <DialogTitle>{title || 'Sorry, your session has expired'}</DialogTitle>
      <DialogContent>
        {content || 'Please refresh the page to update the prices.'}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            sessionStorage.clear();
            navigate('/');
          }}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionExpiredDialog;
