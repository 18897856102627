import { SpeedDial } from '@material-ui/lab';
import styled from 'styled-components';

export const StyledSpeedDial = styled(SpeedDial)`
  margin: 0px;
  top: auto;
  right: ${({ ismobile }) => (ismobile ? '10px' : '32px')};
  left: auto;
  position: fixed;
  bottom: ${(props) =>
    props.crossed
      ? props.ismobile
        ? '70px'
        : '100px'
      : props.ismobile
      ? '10px'
      : '32px'};
  transition: 0.5s;
`;
