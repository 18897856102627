import ArrowBackIosSharp from '@material-ui/icons/ArrowBackIosSharp';
import ArrowForwardIosSharp from '@material-ui/icons/ArrowForwardIosSharp';

function Arrow(props) {
  const { className, onClick, isBack } = props;
  const styles = {
    display: 'block',
    height: '100%',
    width: '20px',
    color: 'black',
    border: '1px solid #C4C4C4'
  };
  return (
    <div>
      {isBack ? (
        <ArrowBackIosSharp className={className} style={styles} onClick={onClick} />
      ) : (
        <ArrowForwardIosSharp className={className} style={styles} onClick={onClick} />
      )}
    </div>
  );
}

export default Arrow;
