import React from 'react';
import { Grid } from '@material-ui/core';
import PricePerPeriod from '../../CardComponents/PricePerPeriod';
import TotalPricePerPeriod from '../../CardComponents/TotalPricePerPeriod';

const PriceSection = ({ basePrice, ...rest }) => {
  const { period, price, VATIncluded, totalBasePrice, pricePerPeriod } = basePrice || {};
  return (
    <Grid container>
      <Grid item xs={12}>
        <PricePerPeriod
          VATIncluded={VATIncluded}
          period={period}
          basePrice={basePrice}
          isMobileCard={true}
          {...rest}
        />
      </Grid>
      <Grid item xs={12}>
        <TotalPricePerPeriod
          price={price}
          totalBasePrice={totalBasePrice}
          displayPrice={pricePerPeriod !== -1}
          {...rest}
        />
      </Grid>
    </Grid>
  );
};

export default PriceSection;
