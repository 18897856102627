import React from "react";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import PolicyIcon from "@material-ui/icons/Policy";
import AssignmentIcon from "@material-ui/icons/Assignment";
const ItemIcon = ({ icon }) => {
  switch (icon) {
    case "deposit":
      return <CreditCardIcon fontSize="small" />;
    case "fuelPolicy":
    case "fuel":
      return <LocalGasStationIcon fontSize="small" />;
    case "insurance":
      return <AssignmentIcon fontSize="small" />;
    default:
      return <></>;
  }
};

export default ItemIcon;
