import { Divider, Grid } from '@material-ui/core';
import React from 'react';
import { BottomSection, LeftSection, RightSection } from './Components';
import { StyledCard } from './styles/PCCarCard.styled';
import EssentialReasonChips from './Components/LeftSection/Components/EssentialReasonChips/EssentialReasonChips';

const PCCarCard = ({
  image,
  basePrice,
  currencyCode,
  carClass,
  sipp_code,
  carFeaturesProps: { transmissionType },
  carModel,
  numberOfDays,
  carFeaturesProps,
  fuel,
  prices,
  supplier,
  country,
  handleBookButtonClicked,
  carId,
  isLoading,
  year,
  isModelGuaranteed,
  apiType,
  importantItems,
  reservationInformation,
  carSupplierInformation,
  additionalInformation,
  essentialReasons,
  ...rest
}) => {
  const { protection_coverage: protections, included, vatLevel } = prices || {};

  return (
    <StyledCard elevation={0} variant='outlined'>
      <Grid container spacing={1} justifyContent='space-between'>
        {essentialReasons && essentialReasons.length > 0 && (
          <Grid item xs={12}>
            <EssentialReasonChips reasons={essentialReasons} />
          </Grid>
        )}
        <Grid item xs={7}>
          <LeftSection
            isModelGuaranteed={isModelGuaranteed}
            carFeaturesProps={carFeaturesProps}
            carModel={carModel}
            image={image}
            carClass={carClass}
            transmission={transmissionType}
            sipp_code={sipp_code}
            isLoading={isLoading}
            year={year}
            supplier={supplier}
            fuel={fuel}
          />
          <Divider style={{ marginTop: 12, marginBottom: 8 }} />
        </Grid>
        <Grid item xs={5}>
          <RightSection
            vatLevel={vatLevel}
            included={included}
            protections={protections}
            basePrice={basePrice}
            currencyCode={currencyCode}
            numberOfDays={numberOfDays}
            isLoading={isLoading}
            country={country}
            additionalInformation={additionalInformation}
          />
        </Grid>
      </Grid>
      <BottomSection
        sippCode={sipp_code}
        carSupplierInformation={carSupplierInformation}
        disableOrderButton={basePrice && basePrice.pricePerPeriod === -1}
        isLoading={isLoading}
        supplier={supplier}
        country={country}
        handleBookButtonClicked={handleBookButtonClicked}
        carId={carId}
        apiType={apiType}
        importantItems={importantItems}
        reservationInformation={reservationInformation}
        apiData={rest.apiData}
      />
    </StyledCard>
  );
};

export default PCCarCard;
