import React from 'react';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import LocalAirportIcon from '@material-ui/icons/LocalAirport';
import TrainIcon from '@material-ui/icons/Train';
import deliveryIcon from '../../../icons/delivery2.svg';
import portIcon from '../../../icons/port.svg';
import { Icon, StyledSelect } from './styles/Option.styled';
import { getCountryFlagByCode } from '../../../../../../../../data/countries';
import OptionName from './components/OptionName';

const LocationTypeIcon = ({ LocationType }) => {
  switch (LocationType) {
    case 'city':
      return <LocationCityIcon style={{ marginRight: 6 }} />;
    case 'airport':
      return <LocalAirportIcon style={{ marginRight: 6 }} />;
    case 'railway':
      return <TrainIcon style={{ marginRight: 6 }} />;
    case 'port':
      return <Icon src={portIcon} />;
    case 'delivery':
      return <Icon src={deliveryIcon} />;
    default:
      return <></>;
  }
};

const Option = ({ LocationType, LocationName, country, code }) => {
  const flag = getCountryFlagByCode(code);
  return (
    <StyledSelect style={{ width: '100%' }}>
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <LocationTypeIcon LocationType={LocationType} />
          {flag && <img src={flag} alt={code} width={27} height={27} style={{ marginRight: 6 }} />}
          <OptionName name={LocationName} country={country} />
        </div>
      </div>
    </StyledSelect>
  );
};

export default Option;
