import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledLink } from './Components/QuickLinks/styles/QuickLinks.styled';
import { useTheme } from '@material-ui/core';

const Links = () => {
  const { t } = useTranslation();
  const { direction } = useTheme();
  return (
    <div style={{ marginTop: '10px' }}>
      <StyledLink to="/">{t('footer.homePage')} | </StyledLink>

      {/* <StyledLink to='/terms-of-use'>{t('footer.termsOfUse')} | </StyledLink> */}
      <StyledLink to="/faq">{t('footer.carFAQ')} </StyledLink>
      {/* <StyledLink to='/privacy'>Privacy  </StyledLink> */}
    </div>
  );
};

export default Links;
