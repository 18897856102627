import {
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import React from 'react';
import { Conditions, ExpandedPanel, Expansion, ParagraphHeading } from './styledComponents';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ENEuropcarIlRentalConditions = ({ isMobile }) => {
  return (
    <Conditions>
      <Typography>
        <ParagraphHeading>VAT:</ParagraphHeading> Israeli passport holders are subject to pay VAT by law
        including dual citizenship passport holders. Only tourists with B2 or B3 visa class permits are
        waived. Important: The Visa Permit must be presented at the rental desk for VAT to be waived. Airport
        Fee: One time charge of $39 will apply for Pickup or Return of vehicle at the TEL AVIV Ben Gurion &
        EILAT Ramon International Airport locations. One Way Rentals: FREE within all locations, except for
        one way To/From the locations in EILAT (Eilat City & Eilat Ramon Airport) as follows: 1-2 Day Rentals:
        $200 3 Days and over: FREE
      </Typography>
      <Typography>
        <ParagraphHeading>Collision Damage Waiver & Theft Protection (CDW/LDW)</ParagraphHeading> covers
        damages caused to the vehicle, except the minimum Excess Charges based on car group. Theft Waiver
        (THW) protection waiver in case of theft of the rental vehicle, except the minimum Excess Charges
        based on car group. Excess Charge of $500-$1,000 will apply in case of damage or theft according to
        vehicle type. CDW/LDW does not cover damage caused to the Tires, Windscreen, Rooftop and underbody
        damage caused to the vehicle that will be charged accordingly.
        <br />
        <ParagraphHeading>Super CDW/LDW</ParagraphHeading> is optional and will reduce the renter’s
        responsibility to Zero in case of damage to the vehicle. Can be purchased only in conjunction with
        CDW/LDW & THW. SCDW does not cover damage caused to the Tires, Windscreen and Rooftop.
        <br />
        <ParagraphHeading>Super THW (THW-Theft Protection)</ParagraphHeading> is optional and will reduce the
        renter’s responsibility to Zero in case of theft of the rental vehicle. Can be purchased only in
        conjunction with CDW/LDW & THW.
        <br />
        <ParagraphHeading>Liability Coverage (LIA)</ParagraphHeading> - Mandatory for customers that decline
        CDW/LDW & THW, provided these are covered by the credit card company. Required to cover items not
        covered by the credit card ompany as blowout’s, tire/trim damage, underbody damage and vandalism
        caused not as a result of vehicle collision and third party damage.
        <br />
        <ParagraphHeading>CDW/LDW & THW </ParagraphHeading>can be declined only if renter has sufficient
        coverage by a US issued World Master Card or Canadian issued Gold/Platinum Visa/Master Card or Visa
        Premier. Written proof of the credit card company must be provided to confirm valid damage and theft
        coverage's apply for car rentals in Israel. Maximum security guarantee is up to the full value of the
        vehicle if CDW/LDW & THW (Damage & Theft coverage's) are declined. Valid for rentals of maximum 28
        consecutive days.
      </Typography>
      <Typography paragraph>
        <ParagraphHeading>Delivery & Collection</ParagraphHeading> is available on request and requires
        confirmation.
        <br />
        Up to 15 KM from Pickup location: $15 <br />
        Up to 30 KM from Pickup location: $25
        <br />
        Up to 50 KM and over from Pickup location: $40 <br />
        Car models are for guidance only and similar car models may be supplied. Minimum charge per rental day
        is 24 hours. Late returns Late returns of 1 hour and over will be charged at 1/3 of the daily rate. 3
        hours and over will be calculated as one extra day. Use of vehicle will be at full responsibility of
        renter as shown on rental agreement. Every additional driver must be registered on the rental
        agreement as well. Crossing and exiting Israel borders and/or the Palestinian Authority Territories
        with the rental vehicle is strictly forbidden.
        <br />
        <ParagraphHeading>Fuel: </ParagraphHeading>Supplied with a full tank and recommended to return full.
        An additional refueling service fee of 30% will apply for vehicles returned with missing fuel. Early
        return of vehicle prior to the original return date will be charged according to the relevant rental
        period rate (Daily, Weekly, Monthly, Etc). Parking tickets, Traffic violations & Toll roads: An
        additional service fee of ILS 50 will apply for unpaid tickets ticket violations and toll road fees.
      </Typography>
      Free Sell applies for the following car groups:
      <br /> B(EBMR), Q(EBAR), C(ECAR), D(CCAR), E(CDAR, F(IDAR), I(SDAR), IW(CWAR), H(FDAR), M(PDAR). All
      other car groups require specific confirmation. No Show & Cancellations: A fee of $110 (€95) will apply
      in case of No Show. Cancellations must be updated 24 hours prior to Pickup Time. Cancellations for 7-8-9
      seat vehicles must be sent 48 hours prior to Pickup.
      <Typography variant='body1' paragraph>
        Groups B(MBMR), Q(MBAR), C(EBAR), D(ECAR), E(EDAR): Minimum age required is 18 (Additional fee
        applies) + Drivers License valid for at least one year.
        <Typography component='span' variant='body1' paragraph>
          Groups F(EZAR), KX(DDAR), I(CCAR), IW(CWAR), H(CDAR), M(SDAR), MH(RDAR), J(SFBR), T(CPMR), G(IVMR),
          U(IVAR): Minimum age required is 21 (Additional fee applies) + Drivers License valid for at least
          one year.
          <br />
          Groups K(PDAR), R(SCAR), O(LFBR), V(SVAR), V8(FVAR), Y(FVMR), Z(LVAR): Minimum age is 25 + Drivers
          License valid for at least one year.
          <br />
          Groups P(LDAR), W(LCBR): Minimum age required is 28 + Drivers License valid for at least five years.
        </Typography>
        <br />
        <Typography component='span' variant='body1'>
          <ParagraphHeading>Underage Driver:</ParagraphHeading> An additional fee of $12 per day (Applies for
          drivers 18-23 years of age).
        </Typography>
      </Typography>
      <TableContainer component={Paper} style={{ marginTop: '16px' }}>
        <Table size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='left' style={{ width: isMobile ? '95%' : '70%' }}>
                Additional Options
              </TableCell>
              <TableCell align='left' style={{ width: isMobile ? '5%' : '30%' }}>
                Price Per Day
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component='th' scope='row'>
                <ExpandedPanel
                  title='Additional Driver'
                  description='anyone who want to be an authorized additional driver must appear at the time of rental and meet age and main documents requirements, unless other contractual conditions apply. If one of the drivers are Israeli passport holder, VAT 17% will apply for the total price.'
                />
              </TableCell>
              <TableCell align='left'>$4</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component='th' scope='row'>
                <ExpandedPanel
                  title='CSI Child Seat for Infant (0-12 months).'
                  description='Safety seats are mandatory by law in Israel for children up to 9 years old or 1.45 meters in height while traveling in vehicles . Car rental company can provide you an infant car seat to be used rear-facing for infants who are less than one year old and weigh up to 9 kgs. This type of a car seat are for Children with maximum weight of 9 kgs and height of 0.66 meters. Available on request and requires confirmation. Please note that Infant Child Seat style varies per location and car rental company. An infant car seat must be installed by customer himself; car rental employees are not allowed to install the seat.
                            How to use: The infant seat should be installed against the direction of travel, with the baby lying at a 45-degree angle (half-lying), his body supported by the seat, and his head not moving forward in case of braking the vehicle. The seat should be adjusted to the height and weight of the baby, and strapped by special belts integrated in the seat. In addition, the infant seat should be anchored to the car seat using the standard vehicle seat belt. '
                />
              </TableCell>
              <TableCell align='left'>$5</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component='th' scope='row'>
                <ExpandedPanel
                  title='CSB Child Seat for Baby (1-3 years)'
                  description='Child Seat for Baby - Safety seats are mandatory by law in Israel for children up to 9 years old or 1.45 meters in height while traveling in motor vehicles . Car rental company can provide you a baby car seat to be used front facing for childs who are less than 3 years old and weigh 9 kgs and over. Available on request and requires confirmation. Please note that Child seat for baby style varies per location and car rental company.A child seat must be installed by customer himself; car rental employees are not allowed to install the seat.
                            How to use: The child seats in a safety chair in an upright sitting position with his face toward the ride. The seat itself is attached to the car seat by the seat belt, while the toddler is strapped to the seat by special belts over his shoulders. Make sure these belts are tight, but not too tight.'
                />
              </TableCell>
              <TableCell align='left'>$5</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component='th' scope='row'>
                CST Child Seat for Toddler (4-7 years)
              </TableCell>
              <TableCell align='left'>$5</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component='th' scope='row'>
                <ExpandedPanel
                  title='CBS Child Booster Seat (8 years and above)'
                  description="CBS Child Booster Seat  - Safety seats are mandatory by law in Israel for children up to 9 years old or 1.45 meters in height while traveling in motor vehicles. Car rental company can provide you a child booster seat to be used front facing. It is designed for children weighing over 15kg and up to 1.45m and is designed to raise the child and allow him to use the standard seat belt installed in the vehicle. 
                            Available on request and requires confirmation. Please note that Booster Car Seat style varies per location and car rental company. A booster seat must be installed by customer himself; car rental employees are not allowed to install the seat.How to use: The booster seat should allow to the seat belt to pass from the child's shoulder onto the collarbone to the pelvic area. Avoid the situation where the belt passes over the child's head or neck."
                />
              </TableCell>
              <TableCell align='left'>$5</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component='th' scope='row'>
                <ExpandedPanel
                  title='Smartphone - Navigation System/GPS'
                  description=' $15 per day,  Includes Free Smartphone device, Unlimited local & international phone calls, Unlimited Data & Wifi Hotspot up to 5 devices. Most popular navigation applications such: Waze or Google maps are already installed. '
                />
              </TableCell>
              <TableCell align='left'>$15</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component='th' scope='row'>
                <Expansion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                  >
                    <Typography> Roadsafe Assistance</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Express assistance for the following services: Keys locked in the car, lost or broken,
                      Flat tire assistance, Out of fuel or Battery failure? We'll send assistance to get your
                      vehicle started quickly. Payment Methods: Major credit cards are accepted to cover
                      rental costs and additional charges: Visa, MasterCard, Amex, Diners (Credit Card must be
                      on renter's name).
                    </Typography>
                  </AccordionDetails>
                </Expansion>
              </TableCell>
              <TableCell align='left'>$4 (maximum charge $100)</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {/**Services table */}
      <TableContainer component={Paper} style={{ marginTop: '16px' }}>
        <Table size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: isMobile ? '20%' : '30%' }}>VIP Airport Services</TableCell>
              <TableCell style={{ width: isMobile ? '5%' : '10%' }} align='center'>
                Price
              </TableCell>
              <TableCell style={{ width: isMobile ? '75%' : '60%' }}>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component='th' scope='row'>
                VIP Club (Arrival Flight Service)
              </TableCell>
              <TableCell align='center'>$179</TableCell>
              <TableCell>
                {' '}
                Customer pickup at arrival gate, separate border control queue, luggage assistance & escort to
                Europcar Counter.
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component='th' scope='row'>
                VIP Club (Departure Flight Service)
              </TableCell>
              <TableCell align='center'>$179 (Includes up to 6 passengers)</TableCell>
              <TableCell>
                {' '}
                Exit through special gate in terminal 3, express security and check-in process, escort through
                duty free to departure gate.
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component='th' scope='row'>
                GOLD Private Terminal & VIP Lounge (Arrival Flight Service)
              </TableCell>
              <TableCell align='center'> $399 per passenger</TableCell>
              <TableCell>
                {' '}
                Customer pickup at arrival gate and transfer to Private VIP Terminal & Lounge, Border Control,
                pickup of luggage and airport arrangements will be taken care of by the agent, while you enjoy
                food & beverages, including alcoholic drinks at the VIP Lounge and exit to pick up your rental
                car.
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component='th' scope='row'>
                GOLD Private Terminal & VIP Lounge Departure Flight Service)
              </TableCell>
              <TableCell align='center'> $399 per passenger</TableCell>
              <TableCell>
                Customer will be dropped off at the Fattal Private Terminal & Lounge following the return of
                rental vehicle to Europcar. Personal security procedure & Check-In at the VIP Lounge. Enjoy
                food & beverages, including alcoholic drinks at the VIP Lounge, while your luggage, border
                control and airport arrangements are being taken care off by the agent. VIP vehicle will drive
                you from the Fattal Private Terminal Lounge directly to the aircraft or boarding gate.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Conditions>
  );
};

export default ENEuropcarIlRentalConditions;
